<app-header></app-header>

<section class="back fix-back">
  <div class="container">
      <div class="row">
          <a href="/purchase"><span class="lnr lnr-arrow-left"></span>Volver</a>
      </div>
  </div>
</section>

<section class="title-filter">
  <div class="container">
      <div class="row">
          <h6>PEDIDO <span>{{ venta.codigo }}</span></h6>
          <div class="colorbar"><span></span></div>
      </div>
  </div>
</section>

<section class="ch-information chart-detail pur-info">
    <div class="container">
        <div class="row purch-det">
            <div class="detail-p">
                <p *ngIf="pago != null">Forma de pago:
                    <span *ngIf="pago.tipo == 'EFECTIVO'">Contado</span>
                    <span *ngIf="pago.tipo == 'MERCADO_PAGO'">Mercado Pago</span>
                </p>
                <p>Fecha: <span>{{ venta.fechaAlta }}</span></p>
                <p>Estado:
                    <span *ngIf="venta.estado == 'PENDIENTE'">Carrito de compras</span>
                    <span *ngIf="venta.estado == 'ENTREGADO'">Entregada</span>
                    <span *ngIf="venta.estado == 'PENDIENTE_EFECTIVO'">Pago en efectivo pendiente</span>
                    <span *ngIf="venta.estado == 'PAGADA'">Pagada</span>
                    <span *ngIf="venta.estado == 'EN_PREPARACION'">En preparación</span>
                </p>
                <p>Pagado: $
                    <span *ngIf="venta.estado == 'PENDIENTE'">-</span>
                    <span *ngIf="venta.estado == 'ENTREGADO'">{{ venta.monto - venta.envio}}</span>
                    <span *ngIf="venta.estado == 'PENDIENTE_EFECTIVO' && venta.pago.estado == 'PENDIENTE'">0.00</span>
                    <span *ngIf="venta.estado == 'PENDIENTE_EFECTIVO' && venta.pago.estado != 'PENDIENTE'">{{ venta.monto - venta.envio}}</span>
                    <span *ngIf="venta.estado == 'PAGADA'">{{ venta.monto - venta.envio}}</span>
                    <span *ngIf="venta.estado == 'EN_PREPARACION' && venta.pago.estado == 'PENDIENTE'">0.00</span>
                    <span *ngIf="venta.estado == 'EN_PREPARACION' && venta.pago.estado != 'PENDIENTE'">{{ venta.monto - venta.envio}}</span>
                </p>
                <p>Adeudado: $
                    <span *ngIf="venta.estado == 'PENDIENTE'">-</span>
                    <span *ngIf="venta.estado == 'ENTREGADO'">0.00</span>
                    <span *ngIf="venta.estado == 'PENDIENTE_EFECTIVO' && venta.pago.estado == 'REALIZADO'">0.00</span>
                    <span *ngIf="venta.estado == 'PENDIENTE_EFECTIVO' && venta.pago.estado != 'REALIZADO'">{{ venta.monto - venta.envio}}</span>
                    <span *ngIf="venta.estado == 'PAGADA'">0.00</span>
                    <span *ngIf="venta.estado == 'EN_PREPARACION' && venta.pago.estado == 'REALIZADO'">0.00</span>
                    <span *ngIf="venta.estado == 'EN_PREPARACION' && venta.pago.estado != 'REALIZADO'">{{ venta.monto - venta.envio}}</span>
                </p>
            </div>
        </div>
    </div>
</section>
<section class="check-out">

  <div class="container">

      <div class="row chart-table d-none d-md-block">
          <table class="table table-striped">
              <thead>
                  <tr>
                      <th scope="col">Producto</th>
                      <th scope="col">Nombre</th>
                      <th scope="col">Cantidad</th>
                      <th scope="col">Precio</th>
                      <th scope="col">Subotal</th>
                  </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let detalle of detalles">
                      <th scope="row">
                          <div class="box-img-c">
                          <a href="/producto/{{ detalle.producto.amigable }}">
                            <img class="img-fluid" *ngIf="detalle.producto?.foto != null && detalle.producto?.foto != ''" [src]="pathImagen(detalle.producto?.foto)" alt="producto"onerror="this.src='/assets/images/Logo-ER(3).png'">
                            <img class="img-fluid" *ngIf="detalle.producto?.foto == null || detalle.producto?.foto == ''" src="/assets/images/Logo-ER(3).png" alt="producto">
                        </a>
                        </div>
                        </th>
                      <td><a href="/producto/{{ detalle.producto.amigable }}">{{ detalle.producto.nombre }}</a></td>
                      <td class="p-quantity">{{ detalle.cantidad }}</td>

<!--                    <td class="p-price" *ngIf="logueado != null && logueado.lista == 1 && detalle.producto.precioOferta === 'null.00'">{{ detalle.producto.precio1 }}</td>-->
<!--                    <td class="p-price" *ngIf="logueado != null && logueado.lista == 2 && detalle.producto.precioOferta === 'null.00'">{{ detalle.producto.precio2 }}</td>-->
<!--                    <td class="p-price" *ngIf="logueado != null && logueado.lista == 3 && detalle.producto.precioOferta === 'null.00'">{{ detalle.producto.precio3 }}</td>-->
<!--                    <td class="p-price" *ngIf="logueado != null && logueado.lista == 4 && detalle.producto.precioOferta === 'null.00'">{{ detalle.producto.precio4 }}</td>-->
<!--                    <td class="p-price" *ngIf="logueado != null && detalle.producto.precioOferta !== 'null.00' ">{{ detalle.producto.precioO }}</td>-->

                    <td class="p-price" *ngIf="logueado != null && logueado.lista == 1 && !detalle.producto.precioOferta">{{ detalle.producto.precio1 }}</td>
                    <td class="p-price" *ngIf="logueado != null && logueado.lista == 2 && !detalle.producto.precioOferta">{{ detalle.producto.precio2 }}</td>
                    <td class="p-price" *ngIf="logueado != null && logueado.lista == 3 && !detalle.producto.precioOferta">{{ detalle.producto.precio3 }}</td>
                    <td class="p-price" *ngIf="logueado != null && logueado.lista == 4 && !detalle.producto.precioOferta">{{ detalle.producto.precio4 }}</td>
                    <td class="p-price" *ngIf="logueado != null && detalle.producto.precioOferta">{{ detalle.producto.precioOferta }}</td>


                    <td *ngIf="i < mostrar" class="p-price">{{ detalle.monto }}</td>

                      <td class="p-price">{{ detalle.monto }}</td>
                  </tr>
              </tbody>
          </table>
      </div>

      <div class="row d-block d-md-none m-scroll-box">
          <div class="chart-p" *ngFor="let detalle of detalles">
              <div class="row">
                  <div class="col-2">
                      <a href="/producto/{{ detalle.producto.amigable }}">
                        <img class="img-fluid" *ngIf="detalle.producto?.foto != null && detalle.producto?.foto != ''" [src]="pathImagen(detalle.producto?.foto)" alt="producto"onerror="this.src='/assets/images/Logo-ER(3).png'">
                        <img class="img-fluid" *ngIf="detalle.producto?.foto == null || detalle.producto?.foto == ''" src="/assets/images/Logo-ER(3).png" alt="producto">
                    </a>
                  </div>
                  <div class="col-7">
                      <a href="/producto/{{ detalle.producto.amigable }}"><h6>{{ detalle.producto.nombre }}</h6></a>
                      <p>$<span>{{ detalle.monto.toString().substring(0, detalle.monto.toString().indexOf('.')) }},<sup>{{ detalle.monto.toString().substring(detalle.monto.toString().indexOf('.') + 1) }}</sup></span></p>
                  </div>
                  <div class="col-3">
                      <input type="number" class="form-control" id="inputCant" placeholder="1" min="1" disabled [(ngModel)]="detalle.cantidad">
                  </div>
              </div>

              <div class="g-bar"></div>
          </div>
      </div>

      <div class="row prices">
          <p class="first-p">Subtotal: $<span>{{ subTotal.toString().substring(0, subTotal.toString().indexOf('.')) }},<sup>{{ subTotal.toString().substring(subTotal.toString().indexOf('.') + 1) }}</sup></span></p>
      </div>
      <div class="row prices" *ngIf="gastosEntrega != null">
          <p>Gastos de entrega: $<span>{{ gastosEntrega.toString().substring(0, gastosEntrega.toString().indexOf('.')) }},<sup>{{ gastosEntrega.toString().substring(gastosEntrega.toString().indexOf('.') + 1) }}</sup></span></p>
      </div>
      <div class="row prices">
          <p>Descuento: $<span>0,<sup>00</sup></span></p>
      </div>
      <div class="row prices total">
          <p>Total: $<span>{{ total.toString().substring(0, total.toString().indexOf('.')) }},<sup>{{ total.toString().substring(total.toString().indexOf('.') + 1) }}</sup></span></p>
      </div>

  </div>
</section>

<app-footer></app-footer>
