<div class="modal fade show d-block" tabindex="-1" role="dialog" style="background-color: rgba(0,0,0,0.5);">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">

        <button type="button" class="close" aria-label="Close" (click)="cerrarModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
        {{ mensaje }}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="cerrarModal()">OK</button>
      </div>
    </div>
  </div>
</div>
