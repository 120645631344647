import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var AuthService = /** @class */ (function () {
    function AuthService(router) {
        this.router = router;
        this.googleClientId = environment.gidCliente;
        this.userSubject = new BehaviorSubject(null);
        this.user$ = this.userSubject.asObservable();
        this.googleLogInComplete$ = new BehaviorSubject(null);
        this.initializeGoogle();
    }
    // Inicializa el cliente de Google
    AuthService.prototype.initializeGoogle = function () {
        var _this = this;
        if (typeof google !== 'undefined' && google.accounts) {
            google.accounts.id.initialize({
                client_id: this.googleClientId,
                callback: function (response) { return _this.handleCredentialResponse(response); }
            });
            // Renderizar botón para versión desktop
            var btnDesktop = document.getElementById("googleBtn");
            if (btnDesktop) {
                google.accounts.id.renderButton(btnDesktop, {
                    theme: "outline",
                    size: "large",
                    type: "standard",
                    text: "signin_with"
                });
            }
            // Renderizar botón para versión móvil
            var btnMobile = document.getElementById("googleBtnMobile");
            if (btnMobile) {
                google.accounts.id.renderButton(btnMobile, {
                    theme: "outline",
                    size: "large",
                    type: "standard",
                    text: "signin_with"
                });
            }
        }
        else {
            console.error("Google API no está cargado.");
        }
    };
    AuthService.prototype.handleCredentialResponse = function (response) {
        // Decodificar el JWT para obtener los datos del usuario
        var userObject = this.decodeJwt(response.credential);
        // Puedes extraer el nombre y correo del objeto decodificado
        var userName = userObject.given_name;
        var userLastName = userObject.family_name;
        var userEmail = userObject.email;
        // Guardar los datos en localStorage
        localStorage.setItem('googleUserName', userName);
        localStorage.setItem('googleUserLastName', userLastName);
        localStorage.setItem('googleUserEmail', userEmail);
        localStorage.setItem('tokenGoogle', response.credential);
        this.googleLogInComplete$.next(true);
    };
    // Método para iniciar sesión con Google (si es necesario)
    AuthService.prototype.signInWithGoogle = function () {
        return new Promise(function (resolve, reject) {
            if (typeof google !== 'undefined' && google.accounts) {
                google.accounts.id.prompt(function (notification) {
                    if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
                        reject('Google Sign-In fue cancelado o no se pudo mostrar.');
                    }
                });
            }
            else {
                reject('Google API no está cargado.');
            }
        });
    };
    // Decodifica el JWT
    AuthService.prototype.decodeJwt = function (token) {
        var payload = token.split('.')[1];
        return JSON.parse(atob(payload));
    };
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject(i1.Router)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
