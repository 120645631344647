import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Cliente } from 'src/app/entidades/cliente';
import { ClienteServicio } from 'src/app/servicios/cliente.servicio';

@Component({
  selector: 'app-registry',
  templateUrl: './registry.component.html',
  styleUrls: ['./registry.component.scss']
})
export class RegistryComponent implements OnInit {

  emailRegistro: string = null;

  nombreRegistro: string = null;
  apellidoRegistro: string = null;

  tipoCliente: string = 'CONSUMIDOR';

  acepto: boolean = false;

  empresaForm: FormGroup;
  consumidorForm: FormGroup;
  usuarioForm: FormGroup;

  @ViewChild("botonAlerta") botonAlerta: ElementRef;
  @ViewChild("cerrarAlerta") cerrarAlerta: ElementRef;

  mensaje: string = null;

  loginForm: FormGroup;

  constructor(private router: Router, private clienteServicio: ClienteServicio) { }

  ngOnInit() {
    this.inicializarForm();
    this.emailRegistro = localStorage.getItem('emailRegistro');
    this.nombreRegistro = localStorage.getItem('googleUserName');
    this.apellidoRegistro = localStorage.getItem('googleUserLastName');
    if (this.emailRegistro != null) {
      this.usuarioForm.patchValue({ nombreUsuario: this.emailRegistro });
      localStorage.removeItem('emailRegistro');
      if(this.apellidoRegistro != null && this.nombreRegistro != null){
        this.consumidorForm.patchValue({nombreConsumidor: this.nombreRegistro});
        this.consumidorForm.patchValue({apellidoConsumidor: this.apellidoRegistro});
        localStorage.removeItem('googleUserName');
        localStorage.removeItem('googleUserLastName');
      }
    } else {
      this.router.navigate(['/'])
    }
  }

  inicializarForm() {
    this.empresaForm = new FormGroup({
      'nombreEmpresa': new FormControl("", [
        Validators.required
      ]),
      'cuitEmpresa': new FormControl("", [
        Validators.required
      ]),
      'nombreContacto': new FormControl("", [
        Validators.required
      ]),
      'telefonoContacto': new FormControl("", [
        Validators.required
      ])
    });

    this.consumidorForm = new FormGroup({
      'nombreConsumidor': new FormControl("", [
        Validators.required
      ]),
      'apellidoConsumidor': new FormControl("", [
        Validators.required
      ]),
      'telefonoConsumidor': new FormControl("", [
        Validators.required
      ])
    });

    this.usuarioForm = new FormGroup({
      'nombreUsuario': new FormControl("", [
        Validators.required
      ]),
      'contraUsuario': new FormControl("", [
        Validators.required
      ]),
      'contraUsuario2': new FormControl("", [
        Validators.required
      ])
    });
  }

  registrarCliente() {
    let cliente: Cliente;
    if (this.tipoCliente == 'CONSUMIDOR') {
      if (this.consumidorForm.get('nombreConsumidor').value != '' && this.usuarioForm.get('nombreUsuario').value != ''
        && this.usuarioForm.get('contraUsuario').value != '' && this.usuarioForm.get('contraUsuario2').value != ''
        && this.consumidorForm.get('telefonoConsumidor').value != '' && this.consumidorForm.get('apellidoConsumidor').value != '') {
          cliente = {
            id: null,
            nombre: this.consumidorForm.get('nombreConsumidor').value,
            apellido: this.consumidorForm.get('apellidoConsumidor').value,
            email: this.emailRegistro,
            eliminado: null,
            fechaAlta: null,
            fechaBaja: null,
            modificacion: null,
            identificador: null,
            lista: 2,
            clave: this.usuarioForm.get('contraUsuario2').value,
            libertya: null,
            ventas: null,
            direccion: null,
            telefono: this.consumidorForm.get('telefonoConsumidor').value,
            nombreEmpresa: null,
            nombreUsuario: this.usuarioForm.get('nombreUsuario').value
          }
          if (this.usuarioForm.get('contraUsuario').value.length < 8) {
            this.mensaje = "La contraseña debe tener mínimo 8 dígitos.";
            let alert: HTMLElement = this.botonAlerta.nativeElement;
            alert.click();
          } else if (this.usuarioForm.get('contraUsuario').value != this.usuarioForm.get('contraUsuario2').value) {
            this.mensaje = "Las contraseñas no coinciden.";
            let alert: HTMLElement = this.botonAlerta.nativeElement;
            alert.click();
          } else {
            if (this.acepto) {
              this.clienteServicio.registro(cliente).subscribe(res => {
                this.mensaje = "Registro realizado correctamente. Revise su casilla de correo para validar su cuenta.";
                let alert: HTMLElement = this.botonAlerta.nativeElement;
                alert.click();
                setTimeout(() => {
                  let alert: HTMLElement = this.cerrarAlerta.nativeElement;
                  alert.click();
                  this.router.navigate(['/']);
                }, 5000);
              }, error => {
                this.mensaje = "Ocurrió un error al momento de registrarse. Intente nuevamente más tarde.";
                let alert: HTMLElement = this.botonAlerta.nativeElement;
                alert.click();
              });
            } else {
              this.mensaje = "Debe aceptar las políticas de privacidad.";
              let alert: HTMLElement = this.botonAlerta.nativeElement;
              alert.click();
            }
          }
      } else {
        this.mensaje = "Complete todos los campos.";
        let alert: HTMLElement = this.botonAlerta.nativeElement;
        alert.click();
      }
    } else {
      if (this.empresaForm.get('nombreContacto').value != '' && this.usuarioForm.get('nombreUsuario').value != ''
        && this.empresaForm.get('cuitEmpresa').value != '' && this.usuarioForm.get('contraUsuario').value != ''
        && this.usuarioForm.get('contraUsuario2').value != '' && this.empresaForm.get('telefonoContacto').value != ''
        && this.empresaForm.get('nombreEmpresa').value != '') {
          cliente = {
            id: null,
            nombre: this.empresaForm.get('nombreContacto').value,
            apellido: null,
            email: this.emailRegistro,
            eliminado: null,
            fechaAlta: null,
            fechaBaja: null,
            modificacion: null,
            identificador: this.empresaForm.get('cuitEmpresa').value,
            lista: 2,
            clave: this.usuarioForm.get('contraUsuario2').value,
            libertya: null,
            ventas: null,
            direccion: null,
            telefono: this.empresaForm.get('telefonoContacto').value,
            nombreEmpresa: this.empresaForm.get('nombreEmpresa').value,
            nombreUsuario: this.usuarioForm.get('nombreUsuario').value
          }
          if (this.usuarioForm.get('contraUsuario').value.length < 8) {
            this.mensaje = "La contraseña debe tener mínimo 8 dígitos.";
            let alert: HTMLElement = this.botonAlerta.nativeElement;
            alert.click();
          } else if (this.usuarioForm.get('contraUsuario').value != this.usuarioForm.get('contraUsuario2').value) {
            this.mensaje = "Las contraseñas no coinciden.";
            let alert: HTMLElement = this.botonAlerta.nativeElement;
            alert.click();
          } else {
            if (this.acepto) {
              this.clienteServicio.registro(cliente).subscribe(res => {
                this.mensaje = "Registro realizado correctamente. Revise su casilla de correo para validar su cuenta.";
                let alert: HTMLElement = this.botonAlerta.nativeElement;
                alert.click();
                setTimeout(() => {
                  let alert: HTMLElement = this.cerrarAlerta.nativeElement;
                  alert.click();
                  this.router.navigate(['/']);
                }, 5000);
              }, error => {
                this.mensaje = "Ocurrió un error al momento de registrarse. Intente nuevamente más tarde.";
                let alert: HTMLElement = this.botonAlerta.nativeElement;
                alert.click();
              });
            } else {
              this.mensaje = "Debe aceptar las políticas de privacidad.";
              let alert: HTMLElement = this.botonAlerta.nativeElement;
              alert.click();
            }
          }
      } else {
        this.mensaje = "Complete todos los campos.";
        let alert: HTMLElement = this.botonAlerta.nativeElement;
        alert.click();
      }
    }
  }

  checkedTipo(tipo: string) {
    if (this.tipoCliente == tipo) {
      return true;
    } else {
      return false;
    }
  }

  elegirTipo(tipo: string) {
    this.tipoCliente = tipo;
  }

  checkedAcepto() {
    this.acepto = !this.acepto;
  }

limitarCuitLength(event) {
  let cuitValue = event.srcElement.value.trim();

  if (cuitValue.length > 11) {
    cuitValue = cuitValue.slice(0, 11);
  }

  // Ahora solo actualiza el valor del campo cuitEmpresa en lugar del nombre de usuario
  this.empresaForm.patchValue({ cuitEmpresa: cuitValue });
}


}
