import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ClienteServicio } from 'src/app/servicios/cliente.servicio';
import { AuthService } from 'src/app/servicios/autenticacion.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  userGoogle: any = null;
  login = true;
  mostrarModal: boolean = false;
  mensajeModal: string = '';

  @ViewChild("inputEmail") inputEmail: ElementRef;
  @ViewChild("inputEmailM") inputEmailM: ElementRef;
  @ViewChild("botonAlerta") botonAlerta: ElementRef;


  mensaje: string = null;

  loginForm: FormGroup;

  constructor(private router: Router, private clienteServicio: ClienteServicio, private activatedRoute: ActivatedRoute, private authService: AuthService, private ngZone: NgZone) { }

  ngOnInit() {
    if (this.activatedRoute.snapshot.queryParams['t'] != null) {
      this.clienteServicio.recuperarCuenta(this.activatedRoute.snapshot.queryParams['t']).subscribe(res => {}, error => {
        this.mensaje = "Error al recuperar cuenta. Intente nuevamente más tarde.";
        let alert: HTMLElement = this.botonAlerta.nativeElement;
        alert.click();
      })
    }
    this.inicializarForm();

    if (localStorage.getItem('logueado') != null) {
          this.router.navigate(['/']);
        }

    this.authService.googleLogInComplete$.subscribe(authComplete => {
      if(authComplete) {
        this.loguearseConGoogle();
      }
    })

    // Asegurarse de que los botones de Google se inicialicen después de que el DOM esté listo
    setTimeout(() => {
      this.authService.initializeGoogle();
    }, 100);
  }

  inicializarForm() {
    this.loginForm = new FormGroup({
      'usuario': new FormControl("", [
        Validators.required
      ]),
      'clave': new FormControl("", [
        Validators.required
      ])
    });
  }

  loginCliente() {
    if (this.loginForm.get('usuario').value != '' && this.loginForm.get('clave').value != '') {
      let usuario = this.loginForm.get('usuario').value;
      let clave = this.loginForm.get('clave').value;
      this.clienteServicio.login(usuario, clave).subscribe(res => {
        if (res.fechaAlta == null) {
          this.mensaje = "Debe validar su cuenta de email.";
          console.log("Debe validar su cuenta de email.");
          this.mostrarAlertaModal("Debe validar su cuenta de email.");
        } else {
          localStorage.setItem('logueado', JSON.stringify(res));
          this.router.navigate(['/']);
        }
      }, error => {
        this.mensaje = "Email o contraseña incorrecto/s.";
        this.mostrarAlertaModal(this.mensaje);
      })
    } else {
      this.mensaje = "Complete todos los campos.";
      this.mostrarAlertaModal(this.mensaje);
    }    
  }

  irRegistro() {
    this.login = false;
  }

  registrarse() {
    if (this.inputEmail.nativeElement.value != '') {
      if (this.validarEmail(this.inputEmail.nativeElement.value)) {
        this.clienteServicio.buscarPorEmail(this.inputEmail.nativeElement.value).subscribe(res => {
          if (res == null) {
            localStorage.setItem('emailRegistro', this.inputEmail.nativeElement.value);
            this.router.navigate(['/registry']);
          } else {
            this.mensaje = "El email ingresado ya está en uso.";
            this.mostrarAlertaModal(this.mensaje);
          }
        }, error => {
          this.mensaje = "Ocurrió un error al validar el mail. Intente nuevamente más tarde.";
          this.mostrarAlertaModal(this.mensaje);
        });
      } else {
        this.mensaje = "Ingrese una cuenta de email válida.";
        this.mostrarAlertaModal(this.mensaje);
      } 
    } else {
      this.mensaje = "Complete el campo del email a registrarse.";
      this.mostrarAlertaModal(this.mensaje);
    }    
  }

  registrarseM() {
    if (this.inputEmailM.nativeElement.value != '') {
      if (this.validarEmail(this.inputEmailM.nativeElement.value)) {
        this.clienteServicio.buscarPorEmail(this.inputEmailM.nativeElement.value).subscribe(res => {
          if (res == null) {
            localStorage.setItem('emailRegistro', this.inputEmailM.nativeElement.value);
            this.router.navigate(['/registry']);
          } else {
            this.mensaje = "El email ingresado ya está en uso.";
            this.mostrarAlertaModal(this.mensaje);
          }
        }, error => {
          this.mensaje = "Ocurrió un error al validar el mail. Intente nuevamente más tarde.";
          this.mostrarAlertaModal(this.mensaje);
        });
      } else {
        this.mensaje = "Ingrese una cuenta de email válida.";
        this.mostrarAlertaModal(this.mensaje);
      } 
    } else {
      this.mensaje = "Complete el campo del email a registrarse.";
      this.mostrarAlertaModal(this.mensaje);
    }   
  }

  validarEmail(email) {
    let r = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return r.test(email);
  }

  mostrarAlertaModal(mensaje: string) {
    this.mensajeModal = mensaje;
    this.mostrarModal = true;
  }

  mostrarAlerta(mensaje: string) {
    alert(mensaje);
  }
  
  cerrarModal() {
    this.mostrarModal = false;
  }
  

  loguearseConGoogle(){
    const email = localStorage.getItem('googleUserEmail');

    if (email && this.validarEmail(email)) {
      this.clienteServicio.buscarPorEmail(email).subscribe(
            res => {
                if (res == null) {
                    localStorage.setItem('emailRegistro', email);
                    this.ngZone.run(() => {
                      this.router.navigate(['/registry']);
                    });
                } else {
                  const token = localStorage.getItem('tokenGoogle');

                  localStorage.setItem('tokenGoogle', null);
                  localStorage.setItem('logueoConGoogle', null);
              
                  this.clienteServicio.logueoConGoogle(token).subscribe(res =>{
                    if (res.fechaAlta == null) {
                      this.mostrarAlerta("Debe validar su cuenta de email.");
                    } else {
                      localStorage.setItem('logueado', JSON.stringify(res));
                      this.ngZone.run(() => {
                        this.router.navigate(['/']);
                      });
                    }
                  }, error => {
                    this.mostrarAlerta("Error al validar su token de email.");
                  })
                }
            },
            error => {
                this.mensaje = "Ocurrió un error al validar el mail. Intente nuevamente más tarde.";
                this.mostrarAlerta(this.mensaje);
            }
        );
    } else {
        this.mensaje = "Ingrese una cuenta de email válida.";
        this.mostrarAlerta(this.mensaje);
    }
  }

}
