/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./pago-mp-realizado";
import * as i2 from "@angular/common/http";
import * as i3 from "@angular/router";
import * as i4 from "../../servicios/cliente.servicio";
var styles_PagoMPRealizado = [];
var RenderType_PagoMPRealizado = i0.ɵcrt({ encapsulation: 2, styles: styles_PagoMPRealizado, data: {} });
export { RenderType_PagoMPRealizado as RenderType_PagoMPRealizado };
export function View_PagoMPRealizado_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Pago exitoso!"]))], null, null); }
export function View_PagoMPRealizado_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-pago-exitoso", [], null, null, null, View_PagoMPRealizado_0, RenderType_PagoMPRealizado)), i0.ɵdid(1, 114688, null, 0, i1.PagoMPRealizado, [i2.HttpClient, i3.Router, i4.ClienteServicio, i3.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PagoMPRealizadoNgFactory = i0.ɵccf("app-pago-exitoso", i1.PagoMPRealizado, View_PagoMPRealizado_Host_0, {}, {}, []);
export { PagoMPRealizadoNgFactory as PagoMPRealizadoNgFactory };
