declare var google: any;

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private googleClientId = environment.gidCliente;
  private userSubject = new BehaviorSubject<any>(null);
  user$ = this.userSubject.asObservable();
  googleLogInComplete$ = new BehaviorSubject<any>(null);

  constructor(private router: Router) {
    this.initializeGoogle();
  }

  // Inicializa el cliente de Google
  initializeGoogle(): void {
    if (typeof google !== 'undefined' && google.accounts) {
      google.accounts.id.initialize({
        client_id: this.googleClientId,
        callback: (response) => this.handleCredentialResponse(response)
      });
      
      // Renderizar botón para versión desktop
      const btnDesktop = document.getElementById("googleBtn");
      if (btnDesktop) {
        google.accounts.id.renderButton(btnDesktop, { 
          theme: "outline",
          size: "large",
          type: "standard",
          text: "signin_with"
        });
      }

      // Renderizar botón para versión móvil
      const btnMobile = document.getElementById("googleBtnMobile");
      if (btnMobile) {
        google.accounts.id.renderButton(btnMobile, { 
          theme: "outline",
          size: "large",
          type: "standard",
          text: "signin_with"
        });
      }
    } else {
      console.error("Google API no está cargado.");
    }
  }

  handleCredentialResponse(response: any) {
  
    // Decodificar el JWT para obtener los datos del usuario
    const userObject = this.decodeJwt(response.credential);
  
    // Puedes extraer el nombre y correo del objeto decodificado
    const userName = userObject.given_name;
    const userLastName = userObject.family_name;
    const userEmail = userObject.email;
  
    // Guardar los datos en localStorage
    localStorage.setItem('googleUserName', userName);
    localStorage.setItem('googleUserLastName', userLastName);
    localStorage.setItem('googleUserEmail', userEmail);
    localStorage.setItem('tokenGoogle', response.credential);

    this.googleLogInComplete$.next(true);
    
  }

  // Método para iniciar sesión con Google (si es necesario)
  signInWithGoogle(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (typeof google !== 'undefined' && google.accounts) {
        google.accounts.id.prompt((notification) => {
          if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
            reject('Google Sign-In fue cancelado o no se pudo mostrar.');
          }
        });
      } else {
        reject('Google API no está cargado.');
      }
    });
  }

  // Decodifica el JWT
  private decodeJwt(token: string): any {
    const payload = token.split('.')[1];
    return JSON.parse(atob(payload));
  }
}

