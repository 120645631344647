<app-header></app-header>

<section class="register">
    <div class="container">
        <h5>INFORMACIÓN DE LA CUENTA</h5>
        <div class="colorbar"><span></span></div>
        <p>Si desea registrar una empresa por favor seleccione la opción EMPRESA, si eres un particular por favor seleccione CONSUMIDOR FINAL</p>
        <div class="row">
            <div class="form-check">
                <input class="form-check-input" type="radio" name="exampleRadios" id="checkConsumidor" (click)="elegirTipo('CONSUMIDOR')" [checked]="checkedTipo('CONSUMIDOR')">
                <label class="form-check-label" for="checkConsumidor">Consumidor Final</label>
            </div>
            <div class="form-check">
                <input class="form-check-input" type="radio" name="exampleRadios" id="checkEmpresa" (click)="elegirTipo('EMPRESA')" [checked]="checkedTipo('EMPRESA')">
                <label class="form-check-label" for="checkEmpresa">Empresa</label>
            </div>
        </div>
    </div>
</section>

<section id='collapsediv1' class='collapse r-form div1 show'>
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6" *ngIf="tipoCliente == 'EMPRESA'">
                <h6>Datos de la Empresa</h6>
                <form [formGroup]="empresaForm">
                    <div class="form-group">
                        <input type="text" formControlName="nombreEmpresa" class="form-control" placeholder="Ingrese nombre de la empresa">
                    </div>
                  <div class="form-group">
                    <input
                      type="number"
                      formControlName="cuitEmpresa" class="form-control" placeholder="Ingrese número de CUIT de la empresa, sin guiones ni puntos" (input)="limitarCuitLength($event)">
                  </div>

                  <div class="form-group">
                        <input type="text" formControlName="nombreContacto" class="form-control" placeholder="Ingrese nombre de contacto">
                    </div>
                    <div class="form-group">
                        <input type="number" formControlName="telefonoContacto" class="form-control" placeholder="Ingrese número telefónico">
                    </div>
                </form>
            </div>

            <div class="col-12 col-md-6" *ngIf="tipoCliente == 'CONSUMIDOR'">
                <h6>Datos Personales</h6>
                <form [formGroup]="consumidorForm">
                    <div class="form-group">
                        <input type="text" formControlName="nombreConsumidor" class="form-control" placeholder="Ingrese su nombre">
                      </div>
                      <div class="form-group">
                        <input type="text" formControlName="apellidoConsumidor" class="form-control" placeholder="Ingrese su apellido">
                      </div>
                      <div class="form-group">
                        <input type="number" formControlName="telefonoConsumidor" class="form-control" placeholder="Ingrese su número telefónico">
                      </div>
                </form>
            </div>

            <div class="col-12 col-md-6">
                <h6>Datos de usuario</h6>
                <form [formGroup]="usuarioForm" (ngSubmit)="registrarCliente()">
                    <div class="form-group">
                        <input type="email" formControlName="nombreUsuario" class="form-control" placeholder="Ingrese su nombre de usuario" disabled>
                    </div>
                    <div class="form-group">
                        <input type="password" formControlName="contraUsuario" class="form-control" placeholder="Ingrese su contraseña">
                    </div>
                    <div class="form-group">
                        <input type="password" formControlName="contraUsuario2" class="form-control" placeholder="Confirme su contraseña">
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="terminos" (click)="checkedAcepto()">
                        <label class="form-check-label" for="exampleRadios2">Acepto las <a href="/privacy">Políticas de Privacidad</a></label>
                    </div>
                    <button type="submit" class="btn btn-primary">Registrar cuenta</button>
                </form>
            </div>
        </div>
    </div>
</section>

<button #botonAlerta style="display: none;" type="button" data-toggle="modal" data-target="#modalAlerta">
</button>

<div class="modal fade" id="modalAlerta" tabindex="-1" role="dialog" aria-labelledby="modalAlertaLabel"
  aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">

        <button #cerrarAlerta type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
        {{ mensaje }}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-dismiss="modal">OK</button>

      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
