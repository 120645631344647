import { DetalleVenta } from './detalle-venta';
import { Familia } from './familia';
import { Categoria } from './categoria';

export class Producto {
    id: string;
	fechaAlta: Date;
	calificacion: number;
	precio1: string;
	precio2: string;
	precio3: string;
	precio4: string;
	precioOferta: number;
	publicado: boolean;
	disponible: boolean;
	descripcion: string;
	amigable: string;
    eliminado: Date;
	foto: string;
	modificacion: Date;
	baja: Date;
	nombre: string;
	sinonimos: string;
    version: number;
    familia: Familia;
	categoria: Categoria;
	detalleVenta: DetalleVenta;
	codigo: string;
	libertya: string;
	visitas: number;
    compras: number;
	cantidad: number;
	stock: number;
}
