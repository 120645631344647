import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal-alerta',
  templateUrl: './modal-alerta.component.html',
  styleUrls: ['./modal-alerta.component.scss']
})
export class ModalAlertaComponent {
  @Input() mensaje: string = '';
  @Output() modalClosed = new EventEmitter<void>();

  constructor() { }

  ngOnInit() {
  }

  cerrarModal(): void {
    this.modalClosed.emit();
  }

}
