<app-header></app-header>

<section class="m-chart d-block d-md-none">
  <div class="container">
    <button type="button" class="btn chart-button" data-toggle="collapse" data-target="#collapseChart"
      aria-expanded="false" aria-controls="collapseExample">

      <img class="img-fluid" src="/assets/images/supermarket.svg" alt="chart">
      <span class="badge badge-light">{{ carrito.length }}</span>
    </button>
    <div class="collapse" id="collapseChart">
      <div class="card card-body">
        <div class="container">
          <div class="chart-p" *ngFor="let detalle of carrito; index as i">
            <div class="row c-chart">
              <a style="cursor: pointer;" (click)="abrirModalEliminar(detalle, i)"><span class="lnr lnr-cross"></span></a>
            </div>
            <div class="row">
              <div class="col-3 col-lg-3 c-info-img">
                <img class="img-fluid" *ngIf="detalle.producto?.foto != null && detalle.producto?.foto != ''" [src]="pathImagen(detalle.producto?.foto)" alt="producto"onerror="this.src='/assets/images/Logo-ER(3).png'">
                <img class="img-fluid" *ngIf="detalle.producto?.foto == null || detalle.producto?.foto == ''" src="/assets/images/Logo-ER(3).png" alt="producto">
              </div>
              <div class="col-5 col-lg-6">
                <h6 style="-webkit-box-orient: vertical;" id="titulo-unico">{{ detalle.producto?.nombre }}</h6>
                <span>x<span>{{ detalle.cantidad }}</span></span>
              </div>
              <div class="col-4 col-lg-3">
                <p>
                  $<span>{{ detalle.monto.toString().substring(0, detalle.monto.toString().indexOf('.')) }},<sup>{{ detalle.monto.toString().substring(detalle.monto.toString().indexOf('.') + 1) }}</sup></span>
                </p>
              </div>
            </div>
            <div class="row p-character">
              <div class="form-row align-items-center">
                <div class="col-10">
                  <input type="text" class="form-control" placeholder="Color|Tamaño|Medida|Otras" [(ngModel)]="detalle.observacion">
                </div>
                <div class="col-2">
                  <button type="submit" class="btn" (click)="agregarObservacion(detalle)"><span class="lnr lnr-pencil"></span></button>
                </div>
              </div>
            </div>
            <div class="g-bar"></div>
          </div>
          <div class="row total" *ngIf="carrito.length > 0">
            <div class="col-4">Total: $<span> {{ totalCarrito }}</span></div>
            <div class="col-5">
              <a href="/checkout" *ngIf="logueado != null">Finalizar compra</a>
              <a href="/login" *ngIf="logueado == null">Finalizar compra</a>
            </div>
            <div class="col-3"><a style="cursor: pointer;" (click)="abrirModalVaciar()"><span
                  class="lnr lnr-trash"></span>Vaciar</a></div>
          </div>
        </div>

      </div>
    </div>
  </div>
</section>

<section class="back">
  <div class="container">
    <div class="row">
      <a href="#"><span class="lnr lnr-arrow-left"></span>Volver</a>
    </div>
  </div>
</section>

<section class="products">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-8 info-p">
        <div class="row">
          <h6 id="titulo-unico">{{ producto?.nombre }}</h6>
        </div>
        <div class="row" >
          <div *ngIf="producto.precioOferta !== null" style="position: relative;">
            <div style="position: absolute; bottom: 8; right: 2; background-color: #E31A2C; color: white; border-radius: 4px; padding: 7px 17px; font-size: 14px;font-weight: 600;">
              Oferta
            </div>
          </div>
          <div class="col-md-5">
            <img class="img-fluid" *ngIf="producto?.foto != null && producto?.foto != ''" [src]="pathImagen(producto?.foto)" alt="abrochadora"onerror="this.src='/assets/images/Logo-ER(3).png'">
            <img class="img-fluid" *ngIf="producto?.foto == null || producto?.foto == ''" src="/assets/images/Logo-ER(3).png" alt="abrochadora"onerror="this.src='/assets/images/Logo-ER(3).png'">
          </div>
          <div class="col-md-7">

            <div class="p-description">
              <div class="row">
                <p>{{ producto?.descripcion }}</p>
              </div>
            </div>

            <div class="p-price">

              <div class="row" *ngIf="producto.precioOferta !=null">
                <p id="cantidad-text" style="font-size: 17px; font-weight: 600; margin-left: 14px;">Precio
                  <span>${{ producto.precioOferta }}</span>

                </p>
              </div>

              <div class="row" *ngIf="producto.precioOferta == null && logueado == null">
                <p id="cantidad-text" style="font-size: 17px; font-weight: 600; margin-left: 14px;">Precio
                  <span>${{ producto?.precio2.toString().substring(0, producto?.precio2.toString().indexOf('.')) }}</span>
                  <sup>{{ producto?.precio2.toString().substring(producto?.precio2.toString().indexOf('.') + 1) }}</sup>
                </p>
              </div>

              <div class="row precio-contenedor" *ngIf="producto.precioOferta == null && logueado != null && logueado.lista == 1">
                <p id="cantidad-text" style="font-size: 17px; font-weight: 600; margin-left: 14px;">Precio
                  <span>${{ producto?.precio1.toString().substring(0, producto?.precio1.toString().indexOf('.')) }}</span>
                  <sup>{{ producto?.precio1.toString().substring(producto?.precio1.toString().indexOf('.') + 1) }}</sup>
                </p>
              </div>

              <div class="row precio-contenedor" *ngIf="producto.precioOferta == null && logueado != null && logueado.lista == 2">
                <p id="cantidad-text" style="font-size: 17px; font-weight: 600; margin-left: 14px;">Precio
                  <span>${{ producto?.precio2.toString().substring(0, producto?.precio2.toString().indexOf('.')) }}</span>
                  <sup>{{ producto?.precio2.toString().substring(producto?.precio2.toString().indexOf('.') + 1) }}</sup>
                </p>
              </div>

              <div class="row precio-contenedor" *ngIf="producto.precioOferta == null && logueado != null && logueado.lista == 3">
                <p id="cantidad-text" style="font-size: 17px; font-weight: 600; margin-left: 14px;">Precio
                  <span>${{ producto?.precio3.toString().substring(0, producto?.precio3.toString().indexOf('.')) }}</span>
                  <sup>{{ producto?.precio3.toString().substring(producto?.precio3.toString().indexOf('.') + 1) }}</sup>
                </p>
              </div>

              <div class="row precio-contenedor" *ngIf="producto.precioOferta == null && logueado != null && logueado.lista == 4  ">
                <p id="cantidad-text" style="font-size: 17px; font-weight: 600; margin-left: 14px;">Precio
                  <span>${{ producto?.precio4.toString().substring(0, producto?.precio4.toString().indexOf('.')) }}</span>
                  <sup>{{ producto?.precio4.toString().substring(producto?.precio4.toString().indexOf('.') + 1) }}</sup>
                </p>
              </div>
            </div>

            <div
							*ngIf="producto?.stock>0; then productoConStock else productoSinStock">
						</div>

            <ng-template #productoConStock>
              <div class="payment-items">
                <img src="/assets/images/horizontal-line.svg"alt="Separador" style="width: 100%; height: auto;" class="mb-4">
                <div class="row align-items-center">
                  <div>
                    <span class="cantidad-text" style="font-size: 17px; font-weight: 600;margin-left: 10px;">Cantidad</span>
                  </div>
                  <div class="col">
                    <input type="number" class="form-control" id="inputCant" placeholder="1" min="1" [(ngModel)]="producto.cantidad">
                  </div>
                </div>
                <a style="cursor: pointer;" (click)="agregarCarrito(producto)"><button type="button" class="btn btn-secondary" 
                  style="margin-top: 2rem; margin-bottom: 2.5rem; padding: right 100px !important; left: 100px !important;">Comprar</button></a>
              </div>
            </ng-template>

            <ng-template #productoSinStock>
              <p class="btn-agotado">AGOTADO!</p>
            </ng-template>

            <div class="payment">
              <div>
                <p>Medios de pagos</p>
                <img class="img-fluid" src="/assets/images/tarjetas.png" alt="forma de pago">
                <img class="img-fluid" src="/assets/images/bancos.png" alt="forma de pago">
              </div>
            </div>
          </div>
        </div>
        <div class="p-way">
          <div class="col-12 col-lg-4">
            <p class="pago-text"><img class="img-fluid" src="/assets/images/truck.svg" alt="entrega" style="margin-left: 3px;">Envíos a todo el
              país</p>
          </div>
          <div class="col-12 col-lg-4">
            <p class="pago-text"><img class="img-fluid" src="/assets/images/pagar.svg" alt="card"> Cuotas sin interés
            </p>
          </div>
          <div class="col-12 col-lg-4">
            <p class="pago-text"><span class="lnr lnr-cart"></span>Pedidos sin cantidad mínima</p>
          </div>
        </div>
      </div>

      <div class="d-none d-md-block col-md-4">
        <div class="chart" *ngIf="carrito.length > 0">
          <h6 style="font-weight: 400; font-size: 22px;" *ngIf="carrito.length > 0">Mi Carrito</h6>
          <div class="colorbar" *ngIf="carrito.length > 0">
            <span></span>
          </div>
          <div class="scroll" *ngIf="carrito.length > 0">
            <div class="chart-p" *ngFor="let detalle of carrito; index as i">
              <div class="row c-chart">
                <a style="cursor: pointer;" (click)="abrirModalEliminar(detalle, i)">
                  <span class="lnr lnr-trash"></span>
                </a>
              </div>
              <div class="row c-info-p">
                <div class="col-3 col-lg-3 c-info-img">
                  <img class="img-fluid" *ngIf="producto?.foto != null && producto?.foto != ''"
                    [src]="pathImagen(producto?.foto)" alt="{{ producto?.amigable }}"
                    onerror="this.src='/assets/images/Logo-ER(3).png'">
                  <img class="img-fluid" *ngIf="producto?.foto == null || producto?.foto == ''"
                    src="/assets/images/Logo-ER(3).png" alt="{{ producto?.amigable }}">
                </div>
                <div class="col-9 col-lg-9">
                  <h6 class="m-0" style="font-size: 14px;" >{{ detalle.producto?.nombre }}</h6>
                  <div class="d-flex flex-column align-items-start">
                    <p class="m-0">
                      <span class="monto">${{ detalle.monto.toString().substring(0, detalle.monto.toString().indexOf('.')) }},
                        <sup>{{ detalle.monto.toString().substring(detalle.monto.toString().indexOf('.') + 1) }}</sup>
                      </span>
                    </p>
                    <span class="cantidad" style="font-size: 12px;">x{{ detalle.cantidad }}</span>
                  </div>
                </div>
              </div>
              <div class="row p-character">
                <div class="form-row align-items-center">
                  <div class="col-10">
                    <input type="text" class="form-control" placeholder="Color|Tamaño|Medida|Otras"
                      [(ngModel)]="detalle.observacion">
                  </div>
                  <div class="col-2">
                    <button type="submit" class="btn" (click)="agregarObservacion(detalle)">
                      <span class="lnr lnr-pencil"></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
          <div class="empty-c" *ngIf="carrito.length > 0">
            <button data-toggle="modal" data-target="#modalVaciar"
              class="btn ept" (click)="abrirModalVaciar()">
              Vaciar Carrito
            </button>
          </div>
          <div class="t-chart" *ngIf="carrito.length > 0">
            <div class="total-container">
              <p>
                Total <span>$<span>{{ totalCarrito }}</span></span>
              </p>
            </div>
            <a href="/checkout" *ngIf="logueado != null">
              <button type="button" class="btn btn-secondary mt-4">Finalizar compra</button>
            </a>
            <a href="/login" *ngIf="logueado == null">
              <button type="button" class="btn btn-secondary mt-4">Finalizar compra</button>
            </a>
          </div>
          <button type="button" class="btn-link-style medios" data-toggle="modal" data-target="#exampleModal">
            Ver medios de pago
          </button>
          <div class="publicidad">
            <div id="carouselExampleSlidesOnly" class="carousel slide"
              data-ride="carousel">
              <div class="carousel-inner">
                <div class="carousel-item container"
                  *ngFor="let oferta of ofertas[0]; index as i"
                  [ngClass]="{ 'active': i == 0 }">
                  <div class="row">
                    <div class="col-6 img-slide">
                      <a href="/producto/{{ oferta.producto.amigable }}"> <img
                        class="d-block w-100 img-fluid"
                        *ngIf="oferta.producto?.foto != null && oferta.producto?.foto != ''"
                        [src]="pathImagen(oferta.producto?.foto)" alt="First slide" onerror="this.src='/assets/images/Logo-ER(3).png'">
                        <img class="d-block w-100 img-fluid"
                        *ngIf="oferta.producto?.foto == null || oferta.producto?.foto == ''"
                        src="/assets/images/Logo-ER(3).png" alt="First slide">
                      </a>
                    </div>
                    <div class="col-6 slide-info">
                      <div class="slide-p">
                        <a href="/producto/{{ oferta.producto.amigable }}">
                          <h6 style="-webkit-box-orient: vertical;">{{
                            oferta.producto.nombre }}</h6>
                        </a>
                      </div>
                      <p>
                        <span class="monto-text">${{ oferta.monto }}</span>
                      </p>
                      <div
                        *ngIf="producto?.stock>0 === true; then productoConStock else productoSinStock">
                      </div>
  
                      <ng-template #productoConStock>
                        <a style="cursor: pointer;"
                           (click)="agregarCarritoOferta(oferta)"
                           class="buy-c d-none d-lg-block">
               Comprar</a>
                      </ng-template>
  
                      <ng-template #productoSinStock>
                        <p class="btn-agotado">Agotado</p>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="publicidad-custom container"
          *ngIf="banner != null && carrito.length > 0">
          <div class="row">
            <a href="https://{{ banner?.link }}"><img class="img-fluid"
              [src]="pathImagen(banner?.pathImagen)" alt=""></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">En 12 cuotas con tarjetas</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <img class="img-fluid" src="./assets/images/tarjetas.png" alt="tarjetas">
        <p>Ingresas los datos de tu tarjeta de crédito una sola vez. En las compras siguientes solo te
          pediremos el código de seguridad.</p>
        <a href="https://www.mercadopago.com.ar/cuotas">Bancos que ofrecen cuotas sin interés</a>
        <div class="row">
          <img class="img-fluid" src="./assets/images/lnr-clock.svg" alt="entrega">
          <p>Acreditación instantanea.</p>
        </div>
        <div class="row">
          <img class="img-fluid" src="./assets/images/truck.svg" alt="entrega">
          <p>Entrega del pedido dentro de los 2 dias hábiles de realizado el pago.</p>
        </div>

        <h5>En efectivo en Puntos de Pagos</h5>
        <img class="img-fluid" src="./assets/images/bancos.png" alt="bancos">
        <p>Al momento de pagar te diremos como hacerlo en cualquier sucursal de <b>Pago Fácil, Rápipago,
            Provincia Pagos</b> o en algun cajero automático de <b>Banelco, Red Link o Home
            Banking</b> adherido.</p>
        <div class="row">
          <img class="img-fluid" src="./assets/images/lnr-clock.svg" alt="entrega">
          <p>Acreditación de 1 a 3 días hábiles</p>
        </div>
        <div class="row">
          <img class="img-fluid" src="./assets/images/truck.svg" alt="entrega">
          <p>Entrega del pedido dentro de los 5 días hábiles de realizado el pago.</p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-dismiss="modal">OK</button>
      </div>
    </div>
  </div>
</div>

<button #botonAlerta style="display: none;" type="button" data-toggle="modal" data-target="#modalAlerta">
</button>

<div class="modal fade" id="modalAlerta" tabindex="-1" role="dialog" aria-labelledby="modalAlertaLabel"
  aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">

        <button #cerrarAlerta type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
        {{ mensaje }}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-dismiss="modal">OK</button>

      </div>
    </div>
  </div>
</div>

<button #botonModalEliminar style="display: none;" type="button" data-toggle="modal" data-target="#modalEliminar">
</button>

<div class="modal fade modal-mp" id="modalEliminar" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
        <div class="modal-header">
            <button #cerrarModalEliminar type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body text-center">
          Está a punto de eliminar este item de su carrito de compras.
        </div>
        <div class="modal-body">
            <div class="botones-pago">
                <button class="btn btn-primary btn-pago" (click)="cancelarEliminar()">Cancelar</button>
                <button class="btn btn-primary btn-pago" (click)="eliminarCarrito()">Confirmar</button>
            </div>
        </div>
    </div>
  </div>
</div>

<button #botonModalVaciar style="display: none;" type="button" data-toggle="modal" data-target="#modalVaciar">
</button>

<div class="modal fade modal-mp" id="modalVaciar" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
        <div class="modal-header">
            <button #cerrarModalVaciar type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body text-center">
          Está a punto de vaciar su carrito de compras.
        </div>
        <div class="modal-body">
            <div class="botones-pago">
                <button class="btn btn-primary btn-pago" (click)="cancelarVaciar()">Cancelar</button>
                <button class="btn btn-primary btn-pago" (click)="vaciarCarrito()">Confirmar</button>
            </div>
        </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
