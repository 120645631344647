import * as tslib_1 from "tslib";
/// <reference types="@types/googlemaps" />
import { OnInit, ElementRef, ChangeDetectorRef } from '@angular/core';
import { VentaServicio } from 'src/app/servicios/venta.servicio';
import { Router } from '@angular/router';
import { ProductoServicio } from 'src/app/servicios/producto.servicio';
import { Direccion } from 'src/app/entidades/direccion';
import { ClienteServicio } from 'src/app/servicios/cliente.servicio';
import { DepartamentoServicio } from 'src/app/servicios/departamento.service';
import { MapsAPILoader } from '@agm/core';
import { DescuentoServicio } from 'src/app/servicios/descuento.service';
import { environment } from 'src/environments/environment';
var CheckOutComponent = /** @class */ (function () {
    function CheckOutComponent(router, ventaServicio, productoServicio, clienteServicio, departamentoServicio, mapsAPILoader, cd, descuentoServicio) {
        var _this = this;
        this.router = router;
        this.ventaServicio = ventaServicio;
        this.productoServicio = productoServicio;
        this.clienteServicio = clienteServicio;
        this.departamentoServicio = departamentoServicio;
        this.mapsAPILoader = mapsAPILoader;
        this.cd = cd;
        this.descuentoServicio = descuentoServicio;
        this.marker = null; // Define el marcador
        this.nuevaDireccion = null;
        this.paso = 1;
        this.carrito = [];
        this.subTotal = null;
        this.subTotalExcluidos = null;
        this.subTotalResto = null;
        this.gastosEntrega = null;
        this.descuentoValor = '0.00';
        this.total = null;
        this.mensaje = null;
        this.totalCarrito = null;
        this.iconoTarjeta = "/assets/images/favicon-16x16.png";
        this.tipoEnvio = 'RETIRO';
        this.direccionSeleccionada = null;
        this.direcciones = [];
        this.tipoPago = null;
        this.mostrar = 5;
        this.mostrando = false;
        this.departamentos = [];
        this.latitude = -32.889961293397434;
        this.longitude = -68.84324181655273;
        this.zoom = 15;
        this.placeId = "";
        this.cantidadOriginal = null;
        this.descuento = { id: null, codigo: null, porcentaje: null };
        this.mapsAPILoader.load().then(function () {
            _this.autocompleteService = new google.maps.places.AutocompleteService();
        });
    }
    CheckOutComponent.prototype.productoExcluido = function (nombreProducto) {
        var keyword = "resma";
        // Convert both the product name and keyword to lowercase for case-insensitive comparison
        var lowercaseNombreProducto = nombreProducto.toLowerCase();
        var lowercaseKeyword = keyword.toLowerCase();
        // Check if the lowercase product name includes the lowercase keyword
        return lowercaseNombreProducto.includes(lowercaseKeyword);
    };
    CheckOutComponent.prototype.handleMercadoPago = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var observaciones, descuento, nombreElemento, cantidadElemento, montoElemento, totalTexto, totalNumerico, email, cantidad, montoUnitario, gastosEnvioElemento, direccionSeleccionada, localidad, domicilioCompleto, tipoEnvio, latitud, longitud, horario, userBuyer, response, preference, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        observaciones = localStorage.getItem('observaciones') || '';
                        descuento = localStorage.getItem('descuentoId') || '';
                        nombreElemento = document.querySelector("[data-nombre]");
                        cantidadElemento = document.querySelector("[data-cantidad]");
                        montoElemento = document.querySelector(".total-span");
                        observaciones = observaciones.replace(/\t+/g, '').trim();
                        if (!nombreElemento || !cantidadElemento || !montoElemento) {
                            throw new Error("Faltan elementos necesarios en el DOM.");
                        }
                        totalTexto = montoElemento.textContent.replace(/\s+/g, '');
                        totalNumerico = parseFloat(totalTexto.replace(',', '.'));
                        if (isNaN(totalNumerico)) {
                            throw new Error("El valor total extraído no es un número válido.");
                        }
                        email = this.logueado.email;
                        cantidad = parseInt(cantidadElemento.getAttribute("data-cantidad"));
                        if (isNaN(cantidad) || cantidad <= 0) {
                            throw new Error("Cantidad no válida.");
                        }
                        montoUnitario = totalNumerico / cantidad;
                        gastosEnvioElemento = localStorage.getItem("montoEnvio");
                        direccionSeleccionada = '';
                        localidad = '';
                        if (this.direccionSeleccionada && this.direccionSeleccionada.domicilio) {
                            direccionSeleccionada = this.direccionSeleccionada.domicilio;
                        }
                        if (this.direccionSeleccionada && this.direccionSeleccionada.localidad) {
                            localidad = this.direccionSeleccionada.localidad;
                        }
                        domicilioCompleto = (direccionSeleccionada + " " + localidad).trim();
                        tipoEnvio = localStorage.getItem('tipoEnvio');
                        latitud = localStorage.getItem('latitud');
                        longitud = localStorage.getItem('longitud');
                        horario = localStorage.getItem('horario');
                        userBuyer = {
                            emailElemento: email,
                            nombreElemento: nombreElemento.getAttribute("data-nombre"),
                            cantidadElemento: cantidad,
                            montoElemento: montoUnitario,
                            gastosEntrega: gastosEnvioElemento,
                            direccion: domicilioCompleto,
                            tipoEnvio: tipoEnvio,
                            observaciones: observaciones,
                            latitud: latitud,
                            longitud: longitud,
                            horario: horario,
                            descuento: descuento
                        };
                        if (!userBuyer.nombreElemento || isNaN(userBuyer.cantidadElemento) || isNaN(userBuyer.montoElemento)) {
                            throw new Error("Datos inválidos en userBuyer.");
                        }
                        return [4 /*yield*/, fetch(environment.baseUrl + "/api/mp", {
                                method: 'POST',
                                headers: {
                                    'Accept': 'Application/json',
                                    'Content-Type': 'Application/json',
                                },
                                body: JSON.stringify(userBuyer)
                            })];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.text()];
                    case 2:
                        preference = _a.sent();
                        this.createCheckoutButton(preference);
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        console.error("Error en handleMercadoPago:", error_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    CheckOutComponent.prototype.createCheckoutButton = function (preference) {
        createCheckoutButton(preference);
    };
    CheckOutComponent.prototype.pagarConMercadoPago = function (nombre, cantidad, monto, email) {
        var userBuyer = {
            email: this.logueado.email.toString,
            title: nombre,
            quanty: cantidad,
            price: monto,
        };
    };
    CheckOutComponent.prototype.ngOnInit = function () {
        var _this = this;
        var mapContainer = document.getElementById('map');
        if (mapContainer) {
            this.map = new google.maps.Map(mapContainer, {
                center: { lat: this.latitude, lng: this.longitude },
                zoom: 12,
            });
            this.actualizarMapa(this.latitude, this.longitude);
            this.map.addListener('click', function (event) {
                if (event.latLng) {
                    _this.handleMapClick(event.latLng.lat(), event.latLng.lng());
                }
            });
        }
        this.obtenerLogueado();
        this.obtenerCarrito();
        this.obtenerDireccion();
        this.obtenerDepartamentos();
        localStorage.removeItem('direccion');
        localStorage.removeItem('horario');
        localStorage.removeItem('observaciones');
        localStorage.removeItem('latitud');
        localStorage.removeItem('longitud');
        localStorage.removeItem('descuentoId');
        window.myFunction = this.angularFunction.bind(this);
    };
    CheckOutComponent.prototype.handleMapClick = function (lat, lng) {
        var _this = this;
        this.actualizarMapa(lat, lng);
        var geocoder = new google.maps.Geocoder();
        geocoder.geocode({ location: { lat: lat, lng: lng } }, function (results, status) {
            if (status === 'OK' && results[0]) {
                var addressComponents = results[0].address_components;
                _this.actualizarCamposDireccion(addressComponents);
            }
            else {
                console.error('Error al obtener la dirección al hacer clic en el mapa:', status);
            }
        });
    };
    CheckOutComponent.prototype.obtenerDireccionDesdeCoordenadas = function (lat, lng) {
        var _this = this;
        var geocoder = new google.maps.Geocoder();
        geocoder.geocode({ location: { lat: lat, lng: lng } }, function (results, status) {
            if (status === 'OK' && results[0]) {
                var addressComponents = results[0].address_components;
                _this.actualizarCamposDireccion(addressComponents);
            }
            else {
                console.error('Error al obtener la dirección:', status);
            }
        });
    };
    CheckOutComponent.prototype.onMapClick = function (event) {
        var _this = this;
        if (this.tipoEnvio === 'ENVIO') {
            var lat = event.coords.lat;
            var lng = event.coords.lng;
            this.latitude = lat;
            this.longitude = lng;
            var geocoder = new google.maps.Geocoder();
            geocoder.geocode({ location: { lat: lat, lng: lng } }, function (results, status) {
                if (status === 'OK' && results[0]) {
                    var addressComponents = results[0].address_components;
                    _this.actualizarCamposDireccion(addressComponents);
                }
                else {
                    console.error('Error al obtener la dirección:', status);
                }
            });
        }
        else {
            this.mensaje = 'Seleccione "Envío a domicilio" para marcar una ubicación en el mapa.';
            var alert_1 = this.botonAlerta.nativeElement;
            alert_1.click();
        }
    };
    CheckOutComponent.prototype.angularFunction = function (data) {
    };
    CheckOutComponent.prototype.mapReady = function ($event) {
        this.map = $event;
    };
    CheckOutComponent.prototype.markerDragEnd = function (event) {
        var _this = this;
        var lat = event.coords.lat;
        var lng = event.coords.lng;
        var geocoder = new google.maps.Geocoder();
        var latLng = new google.maps.LatLng(lat, lng);
        geocoder.geocode({ location: latLng }, function (results, status) {
            if (status === 'OK' && results[0]) {
                var addressComponents = results[0].address_components;
                _this.actualizarCamposDireccion(addressComponents);
            }
            else {
                console.error('Error al obtener la dirección: ', status);
            }
        });
    };
    CheckOutComponent.prototype.actualizarCamposDireccion = function (addressComponents) {
        var address = {
            calle: '',
            numeracion: '',
            provincia: '',
            localidad: '',
            cp: '',
        };
        addressComponents.forEach(function (component) {
            var types = component.types;
            if (types.includes('route')) {
                address.calle = component.long_name;
            }
            if (types.includes('street_number')) {
                address.numeracion = component.long_name;
            }
            if (types.includes('administrative_area_level_1')) {
                address.provincia = component.long_name;
            }
            if (types.includes('locality')) {
                address.localidad = component.long_name;
            }
            if (types.includes('postal_code')) {
                address.cp = component.long_name;
            }
        });
        this.nuevaDireccion = {
            domicilio: address.calle + " " + address.numeracion,
            provincia: address.provincia,
            localidad: address.localidad,
            cp: address.cp,
        };
    };
    CheckOutComponent.prototype.mostrarMas = function () {
        this.mostrar = this.carrito.length;
        this.mostrando = true;
    };
    CheckOutComponent.prototype.elegirTipoPago = function (tipo) {
        this.tipoPago = tipo;
    };
    CheckOutComponent.prototype.confirmarPagoEfectivo = function () {
        var _this = this;
        var dire = null;
        if (this.direccionSeleccionada != null) {
            var domicilio = this.direccionSeleccionada.domicilio;
            var localidad = this.direccionSeleccionada.localidad;
            var departamento = this.direccionSeleccionada.departamento;
            var cp = this.direccionSeleccionada.cp;
            dire = domicilio + ', ' + localidad + ', ' + departamento + ' - ' + cp;
        }
        this.ventaServicio.pagoEnEfectivo(this.logueado.id, localStorage.getItem('observaciones'), this.gastosEntrega, dire, localStorage.getItem('horario'), this.latitude, this.longitude, this.descuento.id).subscribe(function (res) {
            _this.mensaje = "Su solicitud está siendo procesada. Revise su casilla de correo para finalizar el proceso de compra.";
            var alert = _this.botonAlerta.nativeElement;
            alert.click();
            setTimeout(function () {
                _this.cerrarModalFinalizar.nativeElement.click();
                _this.botonCerrarAlerta.nativeElement.click();
                _this.router.navigate(['/']);
            }, 3000);
        }, function (error) {
            _this.mensaje = "Hubo un error al enviar el mail de confirmación. Intente nuevamente más tarde.";
            var alert = _this.botonAlerta.nativeElement;
            alert.click();
        });
    };
    CheckOutComponent.prototype.marcarUbicacion = function () {
        var _this = this;
        var direccion = this.nuevaDireccion.domicilio + ", " + this.nuevaDireccion.localidad + ", " + this.nuevaDireccion.provincia;
        var geocoder = new google.maps.Geocoder();
        geocoder.geocode({ address: direccion }, function (results, status) {
            if (status === 'OK' && results[0]) {
                var location_1 = results[0].geometry.location;
                _this.latitude = location_1.lat();
                _this.longitude = location_1.lng();
                _this.actualizarMapa(location_1.lat(), location_1.lng());
            }
            else {
                console.error('Error al geocodificar la nueva dirección: ', status);
            }
        });
    };
    CheckOutComponent.prototype.elegirDireccion = function (event) {
        var _this = this;
        var direccion = event.srcElement.value;
        if (direccion != 'agregar') {
            this.nuevaDireccion = null;
            this.direccionSeleccionada = new Direccion();
            this.direccionSeleccionada.domicilio = direccion.substring(0, direccion.indexOf(','));
            this.direccionSeleccionada.localidad = direccion.substring(direccion.indexOf(',') + 2, direccion.lastIndexOf(','));
            this.direccionSeleccionada.departamento = direccion.substring(direccion.lastIndexOf(',') + 2, direccion.indexOf(" -"));
            this.direccionSeleccionada.cp = direccion.substring(direccion.indexOf(" -") + 3);
            var dire = this.direccionSeleccionada.domicilio + ', ' + this.direccionSeleccionada.departamento + ', Mendoza';
            this.getPlacePredictions(dire);
            this.departamentos.forEach(function (d) {
                if (d.nombre == _this.direccionSeleccionada.departamento) {
                    _this.departamentoElegido = d;
                }
            });
            var montoMinimo = this.departamentoElegido.montoMinimo;
            if (montoMinimo < Number(this.subTotal)) {
                this.gastosEntrega = '0.00';
            }
            else {
                this.gastosEntrega = this.ordenarDecimales('' + this.departamentoElegido.costoEnvio);
            }
            var geocoder = new google.maps.Geocoder();
            geocoder.geocode({ address: dire }, function (results, status) {
                if (status === 'OK' && results[0]) {
                    var location_2 = results[0].geometry.location;
                    _this.latitude = location_2.lat();
                    _this.longitude = location_2.lng();
                    _this.actualizarMapa(location_2.lat(), location_2.lng());
                }
                else {
                    console.error('No se pudo geocodificar la dirección seleccionada: ', status);
                }
            });
        }
        else {
            this.gastosEntrega = null;
            this.direccionSeleccionada = null;
            this.nuevaDireccion = new Direccion();
        }
    };
    CheckOutComponent.prototype.actualizarMapa = function (lat, lng) {
        if (this.map) {
            this.map.setCenter({ lat: lat, lng: lng });
            this.marker.setPosition({ lat: lat, lng: lng });
        }
    };
    CheckOutComponent.prototype.getPlacePredictions = function (term) {
        var _this = this;
        this.autocompleteService.getPlacePredictions({ input: term }, function (data) {
            _this.placeId = data[0].place_id;
            _this.getLatLong();
        });
    };
    CheckOutComponent.prototype.getLatLong = function () {
        var _this = this;
        var placeService = new google.maps.places.PlacesService(this.map);
        placeService.getDetails({ placeId: this.placeId }, function (result, status) {
            _this.latitude = result.geometry.location.lat();
            _this.longitude = result.geometry.location.lng();
            var alert = _this.mapAgm.nativeElement;
            alert.click();
        });
    };
    CheckOutComponent.prototype.checkedTipo = function (tipo) {
        if (this.tipoEnvio == tipo) {
            return true;
        }
        else {
            return false;
        }
    };
    CheckOutComponent.prototype.setTipoEnvio = function (tipo) {
        this.tipoEnvio = tipo;
        this.nuevaDireccion = null;
        this.direccionSeleccionada = null;
        if (this.tipoEnvio != 'ENVIO') {
            this.gastosEntrega = null;
        }
        this.obtenerTotal();
    };
    CheckOutComponent.prototype.disabledTipo = function () {
        if (this.tipoEnvio == 'RETIRO') {
            return true;
        }
    };
    CheckOutComponent.prototype.irPaso = function (paso) {
        this.paso = paso;
    };
    CheckOutComponent.prototype.obtenerDireccion = function () {
        var _this = this;
        this.clienteServicio.buscarDireccion(this.logueado.id).subscribe(function (res) {
            _this.direcciones = res;
        }, function (error) {
            _this.mensaje = "Hubo un error al cargar las direcciones cargadas. Intente nuevamente más tarde.";
            var alert = _this.botonAlerta.nativeElement;
            alert.click();
        });
    };
    CheckOutComponent.prototype.validarEnvio = function () {
        if (!this.tipoEnvio) {
            return false;
        }
        if (this.tipoEnvio === 'RETIRO') {
            return true;
        }
        if (this.tipoEnvio === 'ENVIO') {
            var horarioValido = this.horarioEnvio &&
                this.horarioEnvio.nativeElement &&
                typeof this.horarioEnvio.nativeElement.value === 'string' &&
                this.horarioEnvio.nativeElement.value.trim().length > 0;
            var direccionValida = this.direccionSeleccionada != null || (this.nuevaDireccion &&
                typeof this.nuevaDireccion.domicilio === 'string' && this.nuevaDireccion.domicilio.trim().length > 0 &&
                typeof this.nuevaDireccion.cp === 'string' && this.nuevaDireccion.cp.trim().length > 0 &&
                typeof this.nuevaDireccion.departamento === 'string' && this.nuevaDireccion.departamento.trim().length > 0 &&
                typeof this.nuevaDireccion.localidad === 'string' && this.nuevaDireccion.localidad.trim().length > 0);
            return horarioValido && direccionValida;
        }
        return false;
    };
    CheckOutComponent.prototype.pasoSiguiente = function () {
        var _this = this;
        if (this.tipoEnvio == 'ENVIO') {
            localStorage.setItem('tipoEnvio', this.tipoEnvio);
            var domicilio = this.direccionSeleccionada
                ? this.direccionSeleccionada.domicilio
                : this.nuevaDireccion
                    ? this.nuevaDireccion.domicilio
                    : '';
            if (domicilio) {
                localStorage.setItem('ubicacion', domicilio);
            }
            else {
                console.error("Error: No se ha ingresado un domicilio válido.");
            }
        }
        if (this.tipoEnvio == 'RETIRO') {
            if (this.observaciones.nativeElement.value != '') {
                localStorage.setItem('observaciones', this.observaciones.nativeElement.value);
            }
            this.irPaso(2);
        }
        else {
            if (this.nuevaDireccion == null) {
                this.irPaso(2);
                var dire = this.direccionSeleccionada.domicilio + ', ' + this.direccionSeleccionada.localidad + ', ' + this.direccionSeleccionada.departamento + ' - ' + this.direccionSeleccionada.cp;
                localStorage.setItem('direccion', dire);
                localStorage.setItem('horario', this.horarioEnvio.nativeElement.value);
                localStorage.setItem('montoEnvio', this.gastosEntrega);
                localStorage.setItem('latitud', '' + this.latitude);
                localStorage.setItem('longitud', '' + this.longitude);
                if (this.observaciones.nativeElement.value != '') {
                    localStorage.setItem('observaciones', this.observaciones.nativeElement.value);
                }
                this.obtenerTotal();
            }
            else {
                if (this.nuevaDireccion.cp && this.nuevaDireccion.departamento && this.nuevaDireccion.domicilio &&
                    this.nuevaDireccion.localidad) {
                    var dire_1 = this.nuevaDireccion.domicilio + ', ' + this.nuevaDireccion.localidad + ', ' + this.departamentoElegido.nombre + ' - ' + this.nuevaDireccion.cp;
                    this.clienteServicio.agregarDireccion(dire_1, this.logueado.id).subscribe(function (res) {
                        _this.mensaje = "Dirección agregada al cliente.";
                        var alert = _this.botonAlerta.nativeElement;
                        alert.click();
                        _this.direccionSeleccionada = tslib_1.__assign({}, _this.nuevaDireccion);
                        localStorage.setItem('direccion', dire_1);
                        localStorage.setItem('horario', _this.horarioEnvio.nativeElement.value);
                        localStorage.setItem('montoEnvio', _this.gastosEntrega);
                        localStorage.setItem('latitud', '' + _this.latitude);
                        localStorage.setItem('longitud', '' + _this.longitude);
                        if (_this.observaciones.nativeElement.value != '') {
                            localStorage.setItem('observaciones', _this.observaciones.nativeElement.value);
                        }
                        setTimeout(function () {
                            _this.irPaso(2);
                            _this.obtenerDireccion();
                            _this.obtenerTotal();
                        }, 3000);
                        localStorage.removeItem('direccion');
                    }, function (error) {
                        _this.mensaje = "Hubo un error al cargar la nueva dirección. Intente nuevamente más tarde.";
                        var alert = _this.botonAlerta.nativeElement;
                        alert.click();
                    });
                }
                else {
                    this.mensaje = "Complete todos los campos de su nueva dirección.";
                    var alert_2 = this.botonAlerta.nativeElement;
                    alert_2.click();
                }
            }
        }
    };
    CheckOutComponent.prototype.obtenerLogueado = function () {
        this.logueado = JSON.parse(localStorage.getItem('logueado'));
        if (this.logueado == null) {
            this.router.navigate(['/']);
        }
    };
    CheckOutComponent.prototype.pathImagen = function (path) {
        return this.productoServicio.getImagen(path);
    };
    CheckOutComponent.prototype.obtenerCarrito = function () {
        var _this = this;
        this.ventaServicio.getCarrito(this.logueado.id).subscribe(function (res) {
            if (res != null) {
                _this.carrito = res['detalleVenta'];
                _this.carrito.forEach(function (i) {
                    i.producto.precio1 = _this.ordenarDecimales(i.producto.precio1);
                    i.producto.precio2 = _this.ordenarDecimales(i.producto.precio2);
                    i.producto.precio3 = _this.ordenarDecimales(i.producto.precio3);
                    i.producto.precio4 = _this.ordenarDecimales(i.producto.precio4);
                    i.producto.precioOferta = _this.ordenarDecimales(i.producto.precioOferta);
                    i.monto = _this.ordenarDecimales(i.monto);
                });
                var productosExcluidos = _this.carrito.filter(function (detalle) { return _this.productoExcluido(detalle.producto.nombre); });
                var productosResto = _this.carrito.filter(function (detalle) { return !_this.productoExcluido(detalle.producto.nombre); });
                var subTotalExcluidos = productosExcluidos.reduce(function (total, detalle) { return total + parseFloat(detalle.monto); }, 0);
                var subTotalResto = productosResto.reduce(function (total, detalle) { return total + parseFloat(detalle.monto); }, 0);
                _this.subTotal = _this.ordenarDecimalesSubtotal(subTotalExcluidos + subTotalResto);
                _this.subTotalExcluidos = _this.ordenarDecimalesSubtotal(subTotalExcluidos);
                _this.subTotalResto = _this.ordenarDecimalesSubtotal(subTotalResto);
                _this.obtenerTotal();
            }
            else {
                _this.router.navigate(['/']);
            }
        }, function (error) {
            _this.mensaje = "Hubo un error al obtener los ítems del carrito. Intente nuevamente más tarde.";
            var alert = _this.botonAlerta.nativeElement;
            alert.click();
        });
    };
    CheckOutComponent.prototype.ordenarDecimalesSubtotal = function (precio) {
        if (Number(precio) % 1 != 0) {
            var decimales = precio.toString().substring(precio.toString().indexOf('.') + 1);
            if (decimales.length == 1) {
                decimales = decimales + '0';
            }
            else if (decimales.length >= 2) {
                decimales = decimales.substring(0, 2);
            }
            precio = precio.toString().substring(0, precio.toString().indexOf('.')) + '.' + decimales;
        }
        else {
            precio = precio + '.00';
        }
        return precio;
    };
    CheckOutComponent.prototype.ordenarDecimales = function (valor) {
        var numero;
        if (Number(valor) % 1 != 0) {
            var decimales = valor.toString().substring(valor.toString().indexOf('.') + 1);
            if (decimales.length == 1) {
                decimales = decimales + '0';
            }
            else if (decimales.length >= 2) {
                decimales = decimales.substring(0, 2);
            }
            numero = valor.toString().substring(0, valor.toString().indexOf('.')) + '.' + decimales;
        }
        else {
            numero = valor + '.00';
        }
        return numero;
    };
    CheckOutComponent.prototype.obtenerTotal = function () {
        var sub = Number(this.subTotalResto);
        var sub2 = Number(this.subTotalExcluidos);
        var gastos = Number(this.gastosEntrega);
        var desc = Number(this.descuentoValor);
        var t;
        if (this.tipoEnvio == 'RETIRO') {
            t = sub - desc + sub2;
        }
        else {
            t = sub - desc + sub2 + gastos;
        }
        this.total = this.ordenarDecimalesSubtotal(t);
        this.totalNumber = +this.total;
    };
    CheckOutComponent.prototype.agregarObservacion = function (detalle) {
        var _this = this;
        this.ventaServicio.agregarObservacion(detalle.id, detalle.observacion).subscribe(function (res) {
            _this.mensaje = "Observación agregada al detalle de venta.";
            var alert = _this.botonAlerta.nativeElement;
            alert.click();
        }, function (error) {
            _this.mensaje = "Hubo un error al agregar su observación al detalle de venta.";
            var alert = _this.botonAlerta.nativeElement;
            alert.click();
        });
    };
    CheckOutComponent.prototype.elegirTarjeta = function (event) {
        if (event.target.value.charAt(0) == '4') {
            this.iconoTarjeta = "/assets/images/visa.jpg";
        }
        else if (event.target.value.charAt(0) == '3') {
            this.iconoTarjeta = "/assets/images/american.jpg";
        }
        else if (event.target.value.charAt(0) == '5') {
            this.iconoTarjeta = "/assets/images/master.jpg";
        }
    };
    CheckOutComponent.prototype.tarjetaIcono = function () {
        return this.iconoTarjeta;
    };
    CheckOutComponent.prototype.botonPagarMP = function () {
        var _this = this;
        this.cerrarModalFinalizar.nativeElement.click();
        this.mensaje = "Se está procesando su pago...";
        if (this.logueado != null) {
            this.ventaServicio.vaciarCarrito(this.logueado.id).subscribe(function (res) {
                _this.obtenerCarrito();
                var alert = _this.botonAlerta.nativeElement;
                alert.click();
                window.location.href = "/";
            }, function (error) {
                _this.mensaje = "Hubo un error al vaciar el carrito. Intente nuevamente más tarde";
                var alert = _this.botonAlerta.nativeElement;
                alert.click();
            });
        }
    };
    CheckOutComponent.prototype.displayModal = function (tipo) {
        if (this.tipoPago == tipo) {
            return 'block';
        }
        else {
            return 'none';
        }
    };
    CheckOutComponent.prototype.guardarCantidadOriginal = function (detalle) {
        var inputCant = document.getElementById('inputCant2' + detalle.id);
        this.cantidadOriginal = parseFloat(inputCant.value);
    };
    CheckOutComponent.prototype.cambiarCantidad = function (detalle) {
        var _this = this;
        if (detalle.cantidad == 0) {
            this.obtenerCarrito();
        }
        else {
            this.ventaServicio.cambiarCantidad(detalle.cantidad, detalle.id, this.logueado.id).subscribe(function (res) {
                _this.mensaje = "Cantidad modificada.";
                var alert = _this.botonAlerta.nativeElement;
                alert.click();
                _this.obtenerCarrito();
            }, function (error) {
                _this.mensaje = "No hay stock suficiente de este producto.";
                var inputCant = document.getElementById('inputCant2' + detalle.id);
                if (inputCant && _this.cantidadOriginal !== null) {
                    inputCant.value = _this.cantidadOriginal.toString();
                }
                var alert = _this.botonAlerta.nativeElement;
                alert.click();
            });
        }
    };
    CheckOutComponent.prototype.obtenerDepartamentos = function () {
        var _this = this;
        this.departamentoServicio.listarDepartamentos().subscribe(function (res) {
            _this.departamentos = res;
        }, function (error) {
            _this.mensaje = "Error al listar departamentos.";
            var alert = _this.botonAlerta.nativeElement;
            alert.click();
        });
    };
    CheckOutComponent.prototype.elegirDepartamento = function (event) {
        var _this = this;
        var i = 0;
        this.departamentos.forEach(function (d) {
            if (i == event.srcElement.value) {
                _this.departamentoElegido = _this.departamentos[i];
            }
            i++;
        });
        var montoMinimo = this.departamentoElegido.montoMinimo;
        if (montoMinimo < Number(this.subTotal)) {
            this.gastosEntrega = '0.00';
        }
        else {
            this.gastosEntrega = this.ordenarDecimales('' + this.departamentoElegido.costoEnvio);
        }
        this.marcarUbicacion();
    };
    CheckOutComponent.prototype.eliminarDetalle = function () {
        var _this = this;
        this.ventaServicio.eliminarDetalle(this.detalleEliminar.id, this.logueado.id).subscribe(function (res) {
            _this.mensaje = "Item eliminado del carrito.";
            var alert = _this.botonAlerta.nativeElement;
            alert.click();
            _this.obtenerCarrito();
            var modal = _this.cerrarModalEliminar.nativeElement;
            modal.click();
        }, function (error) {
            var modal = _this.cerrarModalEliminar.nativeElement;
            modal.click();
            _this.mensaje = "Hubo un error al eliminar item del carrito. Intente nuevamente más tarde.";
            var alert = _this.botonAlerta.nativeElement;
            alert.click();
        });
    };
    CheckOutComponent.prototype.cancelarEliminar = function () {
        this.detalleEliminar = null;
        var alert = this.cerrarModalEliminar.nativeElement;
        alert.click();
    };
    CheckOutComponent.prototype.abrirModalEliminar = function (detalle) {
        this.detalleEliminar = detalle;
        var alert = this.botonModalEliminar.nativeElement;
        alert.click();
    };
    CheckOutComponent.prototype.cargarCodigoDescuento = function () {
        var _this = this;
        if (this.inputCodigo.nativeElement.value != '') {
            this.descuentoServicio.getDescuento(this.inputCodigo.nativeElement.value, this.logueado.id).subscribe(function (res) {
                _this.descuento = res;
                _this.descuentoValor = _this.ordenarDecimales(_this.descuento.porcentaje * Number(_this.subTotalResto) / 100);
                localStorage.setItem('descuentoId', _this.descuento.id);
                _this.obtenerTotal();
                _this.mensaje = "Usted ha cargado un código de " + _this.descuento.porcentaje + "% de descuento.";
                var alert = _this.botonAlerta.nativeElement;
                alert.click();
            }, function (error) {
                if (error['error'].message != null) {
                    _this.mensaje = error['error'].message;
                    var alert_3 = _this.botonAlerta.nativeElement;
                    alert_3.click();
                }
                else {
                    _this.mensaje = "Hubo un error al validar código de descuento. Intente nuevamente más tarde.";
                    var alert_4 = _this.botonAlerta.nativeElement;
                    alert_4.click();
                }
            });
        }
        else {
            this.mensaje = "Complete el campo de código de descuento";
            var alert_5 = this.botonAlerta.nativeElement;
            alert_5.click();
        }
    };
    return CheckOutComponent;
}());
export { CheckOutComponent };
