import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CheckOutComponent } from './componentes/check-out/check-out.component';
import { LoginComponent } from './componentes/login/login.component';
import { RestorePassComponent } from './componentes/restore-pass/restore-pass.component';
import { SearchComponent } from './componentes/search/search.component';
import { SingleProductComponent } from './componentes/single-product/single-product.component';
import { RegistryComponent } from './componentes/registry/registry.component';
import { FaqComponent } from './componentes/faq/faq.component';
import { ChangeInfoComponent } from './componentes/change-info/change-info.component';
import { PrivacyComponent } from './componentes/privacy/privacy.component';
import { PurchaseComponent } from './componentes/purchase/purchase.component';
import { PurchaseDetailComponent } from './componentes/purchase-detail/purchase-detail.component';
import { SupportComponent } from './componentes/support/support.component';
import { ChangePassComponent } from './componentes/change-pass/change-pass.component';
import { IndexComponent } from './componentes/index/index.component';
import { ErrorComponent } from './componentes/error/error.component';
import { NoResultsComponent } from './componentes/no-results/no-results.component';
import { PagoMPRealizado } from './componentes/pago-mp-realizado/pago-mp-realizado';

const routes: Routes = [
  { path: '', component: IndexComponent },
  { path: 'search', component: SearchComponent },
  { path: 'no-results', component: NoResultsComponent },
  { path: 'checkout', component: CheckOutComponent },
  { path: 'registry', component: RegistryComponent },
  { path: 'login', component: LoginComponent },
  { path: 'restore-pass', component: RestorePassComponent },
  { path: 'producto/:amigable', component: SingleProductComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'change-info', component: ChangeInfoComponent },
  { path: 'change-pass', component: ChangePassComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'support', component: SupportComponent },
  { path: 'purchase', component: PurchaseComponent },
  { path: 'purchase/detail', component: PurchaseDetailComponent },
  { path: 'pago-mp-realizado', component: PagoMPRealizado},
  {path: '**', component: ErrorComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: false})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
