import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Cliente } from 'src/app/entidades/cliente';
import { Router, ActivatedRoute } from '@angular/router';
import { Categoria } from 'src/app/entidades/categoria';
import { ProductoServicio } from 'src/app/servicios/producto.servicio';
import { Producto } from 'src/app/entidades/producto';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  logueado: Cliente = null;

  categorias: Categoria[];
  familias: any[];

  productos: Producto[] = [];

  resultados: any[] = null;

  familiasPrincipales: any[] = [];
  otrasFamilias: any[] = [];

  showOtherFamilies: boolean = false;


  @ViewChild("botonAlerta") botonAlerta: ElementRef;

  mensaje: string = null;
  terminoBuscador: string;

  constructor(private router: Router, private productoServicio: ProductoServicio, private activatedRoute: ActivatedRoute) { }
  
  ngOnInit() {
    if (localStorage.getItem('logueado') != null && localStorage.getItem('logueado') != '') {
      this.logueado = JSON.parse(localStorage.getItem('logueado'));
    }
    this.productoServicio.listarFamilias().subscribe((data) => {
        const principales = ["Arte y Dibujo", "Escolar", "Comercial"];
    
        this.familiasPrincipales = data.filter(f => principales.includes(f.nombre));
        this.otrasFamilias = data.filter(f => !principales.includes(f.nombre));
        
    });
    
    this.listarCategorias();
    this.listarFamilias();
  }

  toggleOtherFamilies(event: Event) {
    this.showOtherFamilies = !this.showOtherFamilies;
  }

  logout() {
    localStorage.clear();
    window.location.reload();
  }

  listarCategorias() {
    this.productoServicio.listarCategorias().subscribe(res => {
      this.categorias = res;
    }, error => {
      this.mensaje = "Ha ocurrido un error al momento de listar las categorías.";
      let alert: HTMLElement = this.botonAlerta.nativeElement;
      alert.click();
    });
  }

  listarFamilias() {
    this.productoServicio.listarFamilias().subscribe(res => {
      this.familias = res;
    }, error => {
      this.mensaje = "Ha ocurrido un error al momento de listar las familias.";
      let alert: HTMLElement = this.botonAlerta.nativeElement;
      alert.click();
    });
  }

  productosPorCategoria(id) {
    localStorage.setItem('categoriaId', id);
    localStorage.removeItem('nombreBusqueda');
    if (this.activatedRoute.snapshot.url.length == 0 || this.activatedRoute.snapshot.url[0].path != "search") {
      this.router.navigate(['/search'], {queryParams: {categoria_id: localStorage.getItem('categoriaId')}});
    } else {
      this.router.navigate(['/search'], {queryParams: {categoria_id: localStorage.getItem('categoriaId')}}).then(() => {
        window.location.reload();
      });
    }
  }

  productosPorFamilia(id) {
    this.router.navigate(['/search'], { queryParams: { familia_id: id } });
  }
  
  cargarProductos(familiaId: number) {
  
    this.productoServicio.pageProductosPorFamilia(familiaId, 0).subscribe(data => {
      this.productos = data.content;
    });
  }

  buscar(event) {
    this.terminoBuscador = event.srcElement.value;
    if (event.srcElement.value != '') {
      this.productoServicio.buscarPorNombre(event.srcElement.value).subscribe(res => {
        if (res.length > 0) {
          this.resultados = res;
        } else {
          this.resultados = [];
        }
      }, error => {
        this.mensaje = "Ha ocurrido un error al momento de hacer la búsqueda. Intente nuevamente más tarde.";
        let alert: HTMLElement = this.botonAlerta.nativeElement;
        alert.click();
      });
    } else {
      this.resultados = null;
    }
  }

  traerResultados(event, id) {
    if (event != null) {
      if (event.keyCode == 13) {
        localStorage.removeItem('categoriaId');
        localStorage.setItem('nombreBusqueda', event.srcElement.value);
        if (this.activatedRoute.snapshot.url.length == 0 || this.activatedRoute.snapshot.url[0].path != "search") {
          this.router.navigate(['/search'], {queryParams: {term: localStorage.getItem('nombreBusqueda')}});
        } else {
          this.router.navigate(['/search'], {queryParams: {term: localStorage.getItem('nombreBusqueda')}}).then(() => {
            window.location.reload();
          });
        }
      }
    } else if (id != null) {
      localStorage.removeItem('categoriaId');
      localStorage.setItem('nombreBusqueda', this.terminoBuscador);
      localStorage.setItem('idDestacado', id);
      if (this.activatedRoute.snapshot.url.length == 0 || this.activatedRoute.snapshot.url[0].path != "search") {
        this.router.navigate(['/search'], {queryParams: {id: localStorage.getItem('idDestacado')}});
      } else {
        this.router.navigate(['/search'], {queryParams: {id: localStorage.getItem('idDestacado')}}).then(() => {
          window.location.reload();
        });
      }
    }
  }

  onMenuClick(event: Event) {
    const links = document.querySelectorAll('.menu-link');
    links.forEach(link => link.classList.remove('active'));

    const target = event.target as HTMLElement;
    target.classList.add('active');
  }
}
