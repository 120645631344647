import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ClienteServicio } from 'src/app/servicios/cliente.servicio';
import { Cliente } from 'src/app/entidades/cliente';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pago-exitoso',
  template: '<p>Pago exitoso!</p>'
})

export class PagoMPRealizado implements OnInit{
    logueado: Cliente;
    paymentId: any;
    

    constructor(private http: HttpClient, private router: Router, private clienteServicio: ClienteServicio, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.obtenerLogueado();

    this.paymentId = this.route.snapshot.queryParams['payment_id'];
    console.log('Este es el ID DE PAGOOOOOO: ' + this.paymentId);

    const gastosEnvioElemento = document.querySelector("#gastoEntrega span");

    const gastosEnvio = gastosEnvioElemento ? parseFloat(gastosEnvioElemento.textContent.replace(/[^\d,]/g, '').replace(',', '.')) : 0;
    const direccionSeleccionada = localStorage.getItem('ubicacion');
    const tipoEnvio = localStorage.getItem('tipoEnvio');
    const costoEnvio = localStorage.getItem('montoEnvio');

    localStorage.removeItem('tipoEnvio');
    localStorage.removeItem('ubicacion');
    localStorage.removeItem('montoEnvio');

    const userCompra = {
        cliente: this.logueado,
        idPago: this.paymentId,
        gastosEntrega: costoEnvio,
        direccion: direccionSeleccionada,
        tipoEnvio: tipoEnvio,
        
    };

    if (this.logueado != null) {
        this.http.post(`${environment.baseUrl}/api/mp/pago-exitoso`, userCompra)
          .subscribe((response) => {
            // Al terminar, redirige al /purchase
            this.router.navigate(['/purchase']);
          });
      }
  }

  obtenerLogueado() {
    this.logueado = JSON.parse(localStorage.getItem('logueado'));
    if (this.logueado == null) {
        this.router.navigate(['/']);
    }
}
}