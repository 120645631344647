<app-header></app-header>

<section class="forms">
  <div class="container">
    <div class="row">

      <div class="col-12 col-md-6  d-none d-md-block">
        <div class="gray" style="height: 300px;">
          <h5>INGRESAR</h5>
          <div class="colorbar"><span></span></div>
          <form [formGroup]="loginForm" (ngSubmit)="loginCliente()">
            <div class="form-group">
              <input formControlName="usuario" type="text" class="form-control" placeholder="Ingrese su nombre de usuario">
            </div>
            <div class="form-group">
              <input formControlName="clave" type="password" class="form-control" placeholder="Ingrese su contraseña">
            </div>
            <button type="submit" class="btn btn-primary">Ingresar a su cuenta</button>
          </form>

          <a href="/restore-pass">¿Olvidó sus datos?</a>
        </div>
      </div>

      <div class="col-12 col-md-6 d-none d-md-block">
        <div class="gray n-user" style="height: 220px;">
          <h5>¿NUEVO USUARIO?</h5>
          <div class="colorbar"><span></span></div>
          <form>
            <div class="form-group">
              <input #inputEmail type="email" class="form-control" placeholder="Ingrese su nombre de email">
            </div>
            <button type="submit" class="btn btn-primary" (click)="registrarse()">Registrar nueva cuenta</button>
          </form>
          <a href="https://api.whatsapp.com/send?phone=2616607476" class="contact-link">
            Si necesita ayuda, contáctenos
          </a>
        </div>
          <br>
          <h6 style="text-align: center;">Acceder o registrarse con</h6>
          <div style="display: flex; align-items: center; justify-content: center;">
            <div id="googleBtn"></div>
          </div>
      </div>

      <div class="col-12 col-md-6  d-block d-md-none" *ngIf="login">
        <div class="gray" style="height: 420px;">
          <h5>INGRESAR</h5>
          <div class="colorbar"><span></span></div>
          <form [formGroup]="loginForm" (ngSubmit)="loginCliente()">
            <div class="form-group">
              <input formControlName="usuario" type="text" class="form-control" placeholder="Ingrese su nombre de usuario">
            </div>
            <div class="form-group">
              <input formControlName="clave" type="password" class="form-control" placeholder="Ingrese su contraseña">
            </div>
            <button type="submit" class="btn btn-primary">Ingresar a su cuenta</button>
          </form>

          <div class="text-center mt-4">
            <div style="margin: 10px auto;">
              <div id="googleBtnMobile"></div>
            </div>
          </div>

          <div class="d-flex justify-content-center gap-4 mt-4">
            <a href="/restore-pass" style="color: #666; font-size: 12px; margin: 0 10px; text-align: center; width: 80px;">¿Olvidó sus datos?</a>
            <a class="d-block d-md-none" style="cursor: pointer; color: #1D1186; font-size: 12px; margin: 0 10px; text-align: center; width: 80px;" (click)="irRegistro()">
              Registrar nueva cuenta
            </a>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 d-block d-md-none" *ngIf="!login">
        <div class="gray n-user">
          <h5>¿NUEVO USUARIO?</h5>
          <div class="colorbar"><span></span></div>
          <form>
            <div class="form-group">
              <input #inputEmailM type="email" class="form-control" placeholder="Ingrese su cuenta de email"
                aria-describedby="emailHelp">
            </div>
            <button type="submit" class="btn btn-primary" (click)="registrarseM()">Registrar nueva cuenta</button>
          </form>
          <p>Si necesita ayuda, contáctenos</p>
        </div>
      </div>

    </div>
  </div>
</section>

<app-modal-alerta 
  *ngIf="mostrarModal" 
  [mensaje]="mensajeModal" 
  (modalClosed)="cerrarModal()">
</app-modal-alerta>

<app-footer></app-footer>
