<app-header></app-header>
<script src="https://sdk.mercadopago.com/js/v2"></script>
<script src="assets/js/mercado-pago.js"></script>
<section class="title-filter">
	<div class="container">
		<div class="row">
			<h6>FINALIZAR PEDIDO</h6>
			<div class="colorbar">
				<span></span>
			</div>
		</div>
	</div>
</section>

<section class="check-out">
	<div class="container">
		<div class="row progress-state">
			<div class="col-12 ">
				<div class="red-back">
					<p class="p-rounded"
						[ngClass]="{ 'p-active' : paso == 1, 'p-inactive' : paso == 2}">
						<span>1</span> Domicilio de envío
					</p>
					<p class="p-gray"
						[ngClass]="{ 'p-active' : paso == 2, 'p-inactive' : paso == 1}">
						<span>2</span> Finalizar pedido
					</p>
				</div>
			</div>
		</div>
	</div>

	<div class="container" *ngIf="paso == 1">
		<div class="row step">
			<p>Paso 1: Información de envío</p>
		</div>
		<div class="row">
			<h6>Modo de envío</h6>
			<div class="colorbar">
				<span></span>
			</div>
		</div>
		<div class="row s-check">
			<div class="form-check">
				<input class="form-check-input" type="radio" name="exampleRadios"
					id="checkRetiro" value="RETIRO" (click)="setTipoEnvio('RETIRO')"
					[checked]="checkedTipo('RETIRO')"> <label
					class="form-check-label" for="checkRetiro"> <span
					class="lnr lnr-home d-none d-md-block"></span>Retiro mi pedido en
					Papelería Entre Ríos
				</label>
			</div>
			<div class="form-check">
				<input class="form-check-input" type="radio" name="exampleRadios"
					id="checkEnvio" value="ENVIO" (click)="setTipoEnvio('ENVIO')"
					[checked]="checkedTipo('ENVIO')"> <label
					class="form-check-label" for="checkEnvio"> <img
					src="/assets/images/truck.svg" class="img-fluid d-none d-md-block"
					alt="envio">Envío a domicilio
				</label>
			</div>
		</div>
		<div class="row time-title">
			<p>Horario de entrega</p>
		</div>
		<div class="row s-time">
			<select class="custom-select" id="inputGroupSelect02"
				[disabled]="disabledTipo()" #horarioEnvio>
				<option value="De 9hs a 13hs">De 9hs a 13hs</option>
				<option value="De 16hs a 20hs">De 16hs a 20hs</option>
			</select>
			<p>
				<span class="lnr lnr-alarm"></span>Recuerde que la entrega se
				realizará a partir de las 72 hs hábiles posteriores a la
				realización del pedido
			</p>
		</div>
		<div class="row time-title">
			<p>Domicilio de envío</p>
		</div>
		<div class="row s-input">
			<div class="col-md-6">
				<select
					*ngIf="direcciones != [] && direccionSeleccionada == null && nuevaDireccion == null"
					class="custom-select" id="inputGroupSelect01"
					(change)="elegirDireccion($event)" [disabled]="disabledTipo()">
					<option selected>Seleccione Domicilio</option>
					<option *ngFor="let direccion of direcciones" [value]="direccion">{{
						direccion }}</option>
					<option value="agregar">Agregar nueva dirección</option>
				</select> <input
					*ngIf="direccionSeleccionada == null && nuevaDireccion != null"
					class="form-control" type="text"
					[(ngModel)]="nuevaDireccion.domicilio"
					placeholder="Ingrese su domicilio" [disabled]="disabledTipo()"
					(change)="marcarUbicacion()"> <input
					*ngIf="direccionSeleccionada != null" class="form-control"
					type="text" [(ngModel)]="direccionSeleccionada.domicilio"
					placeholder="Ingrese su domicilio" disabled>
			</div>
			<div class="col-md-6">
				<input
					*ngIf="direccionSeleccionada == null && nuevaDireccion != null"
					class="form-control" type="text" [(ngModel)]="nuevaDireccion.cp"
					placeholder="Ingrese su código postal" [disabled]="disabledTipo()"
					(change)="marcarUbicacion()"> <input
					*ngIf="direccionSeleccionada != null" class="form-control"
					type="text" [(ngModel)]="direccionSeleccionada.cp"
					placeholder="Ingrese su código postal" disabled>
			</div>
		</div>
		<div class="row s-input">
			<div class="col-md-6">
				<select
					*ngIf="direccionSeleccionada == null && nuevaDireccion != null"
					class="custom-select" [(ngModel)]="nuevaDireccion.departamento"
					[disabled]="disabledTipo()" (change)="elegirDepartamento($event)">
					<option *ngFor="let departamento of departamentos; index as i"
						[value]="i">{{ departamento.nombre }}</option>
				</select> <select *ngIf="direccionSeleccionada != null" class="custom-select"
					[(ngModel)]="direccionSeleccionada.departamento" disabled
					(change)="elegirDepartamento($event)">
					<option *ngFor="let departamento of departamentos"
						[value]="departamento.nombre">{{ departamento.nombre }}</option>
				</select>
			</div>
			<div class="col-md-6">
				<input
					*ngIf="direccionSeleccionada == null && nuevaDireccion != null"
					class="form-control" type="text"
					[(ngModel)]="nuevaDireccion.localidad"
					placeholder="Ingrese su localidad" [disabled]="disabledTipo()"
					(change)="marcarUbicacion()"> <input
					*ngIf="direccionSeleccionada != null" class="form-control"
					type="text" [(ngModel)]="direccionSeleccionada.localidad"
					placeholder="Ingrese su localidad" disabled>
			</div>
			<div class="col-12">
				<div class="form-group">
					<textarea class="form-control" #observaciones id="observaciones" rows="2"
					[disabled]="disabledTipo()"
					placeholder="Observaciones: Entre calles, color de casa, rejas, etc."
					(input)="guardarObservaciones($event)">
				</textarea>
				</div>
			</div>
		</div>

		<div class="row s-msg" *ngIf="gastosEntrega != null">
			<p class="s-cost">
				Gastos de entrega: $<span id="gastoEntrega">{{ gastosEntrega }}</span>
			</p>
			<p class="s-text" *ngIf="gastosEntrega != '0.00'">Con su compra
				mayor a ${{ departamentoElegido.montoMinimo }} el envío es GRATIS</p>
		</div>

		<div id="map" class="row maps" style="width: 100%; height: 300px;">
			<agm-map 
			[latitude]="latitude" 
			[longitude]="longitude" 
			[zoom]="zoom"
			style="height: 100%; width: 100%;"
			(mapClick)="onMapClick($event)"
			[scrollwheel]="true">
			<agm-marker 
			  [latitude]="latitude"
			  [longitude]="longitude"
			  [markerDraggable]="true"
			  (dragEnd)="onMarkerDragEnd($event)">
			</agm-marker>
		  </agm-map>
		  
		</div>

		<div class="row buttons">
			<div class="back-for">
				<button type="button" class="btn forward"  [disabled]="!validarEnvio()" (click)="pasoSiguiente()">
					Paso siguiente<span class="lnr lnr-chevron-right"></span>
				</button>
			</div>
		</div>
	</div>

	<div class="container" *ngIf="paso == 2">
		<div class="row step">
			<p>Paso 2: Finalizar pedido</p>
		</div>
		<div class="row">
			<h6>Productos en tu carrito</h6>
			<div class="colorbar">
				<span></span>
			</div>
		</div>
		<div class="row chart-table d-none d-md-block">
			<table class="table table-striped">
				<thead>
					<tr>
						<th scope="col">Producto</th>
						<th scope="col">Nombre</th>
						<th scope="col">Observaciones</th>
						<th scope="col">Cantidad</th>
						<th scope="col">Precio</th>
						<th scope="col">Total</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let detalle of carrito; index as i">
						<th scope="row" *ngIf="i < mostrar">
							<div class="box-img-c">
								<a href="/producto/{{ detalle.producto.amigable }}">
									<img
										class="img-fluid"
										*ngIf="detalle.producto?.foto != null && detalle.producto?.foto != ''"
										[src]="pathImagen(detalle.producto?.foto)" alt="producto"
										onerror="this.src='/assets/images/Logo-ER(3).png'">
									<img
										class="img-fluid"
										*ngIf="detalle.producto?.foto == null || detalle.producto?.foto == ''"
										src="/assets/images/Logo-ER(3).png" alt="producto">
								</a>
							</div>
						</th>
						<td *ngIf="i < mostrar" class="t-name" [attr.data-nombre]="detalle.producto.nombre">
							<a href="/producto/{{ detalle.producto.amigable }}">{{ detalle.producto.nombre }}</a>
						</td>
						<td *ngIf="i < mostrar">
							<input
								type="text"
								class="form-control"
								placeholder="Color|Tamaño|Medida|Otras"
								[(ngModel)]="detalle.observacion"
								(change)="agregarObservacion(detalle)">
						</td>
						<td *ngIf="i < mostrar" class="p-quantity" [attr.data-cantidad]="detalle.cantidad">
							<input
								type="number"
								class="form-control"
								[attr.id]="'inputCant2' + detalle.id"
								placeholder="1" min="1"
								[(ngModel)]="detalle.cantidad"
								(change)="cambiarCantidad(detalle)"
								(focus)="guardarCantidadOriginal(detalle)">
						</td>
				
						<td class="p-price" *ngIf="logueado.lista == 1 && i < mostrar" [attr.data-monto]="detalle.producto.precioOferta !== 'null.00' ? detalle.producto.precioOferta : detalle.producto.precio1">
							{{ detalle.producto.precioOferta !== 'null.00' ? detalle.producto.precioOferta : detalle.producto.precio1 }}
						</td>
				
						<td class="p-price" *ngIf="logueado.lista == 2 && i < mostrar" [attr.data-monto]="detalle.producto.precioOferta !== 'null.00' ? detalle.producto.precioOferta : detalle.producto.precio2">
							{{ detalle.producto.precioOferta !== 'null.00' ? detalle.producto.precioOferta : detalle.producto.precio2 }}
						</td>
				
						<td class="p-price" *ngIf="logueado.lista == 3 && i < mostrar" [attr.data-monto]="detalle.producto.precioOferta !== 'null.00' ? detalle.producto.precioOferta : detalle.producto.precio3">
							{{ detalle.producto.precioOferta !== 'null.00' ? detalle.producto.precioOferta : detalle.producto.precio3 }}
						</td>
				
						<td class="p-price" *ngIf="logueado.lista == 4 && i < mostrar" [attr.data-monto]="detalle.producto.precioOferta !== 'null.00' ? detalle.producto.precioOferta : detalle.producto.precio4">
							{{ detalle.producto.precioOferta !== 'null.00' ? detalle.producto.precioOferta : detalle.producto.precio4 }}
						</td>
				
						<td *ngIf="i < mostrar" class="p-price" [attr.data-monto]="detalle.monto">
							{{ detalle.monto }}
						</td>
						<td *ngIf="i < mostrar" class="p-delete">
							<a style="cursor: pointer;" (click)="abrirModalEliminar(detalle)">
								<span class="lnr lnr-cross"></span>
							</a>
						</td>
					</tr>
				</tbody>				
			</table>
			<div class="mp">
				<a style="cursor: pointer;" (click)="mostrarMas()"
					*ngIf="carrito.length > 5 && !mostrando">Mostrar más productos</a>
			</div>
		</div>

		<div class="row d-block d-md-none m-scroll-box">
			<div class="chart-p" *ngFor="let detalle of carrito; index as i">
				<div class="row c-chart">
					<a style="cursor: pointer;" (click)="abrirModalEliminar(detalle)"><span
						class="lnr lnr-cross"></span></a>
				</div>
				<div class="row">
					<div class="col-2">
						<a href="/producto/{{ detalle.producto.amigable }}"> <img
							class="img-fluid"
							*ngIf="detalle.producto?.foto != null && detalle.producto?.foto != ''"
							[src]="pathImagen(detalle.producto?.foto)" alt="producto"
							onerror="this.src='/assets/images/Logo-ER(3).png'"> <img
							class="img-fluid"
							*ngIf="detalle.producto?.foto == null || detalle.producto?.foto == ''"
							src="/assets/images/Logo-ER(3).png" alt="producto">
						</a>
					</div>
					<div class="col-7">
						<a href="/producto/{{ detalle.producto.amigable }}">
							<h6>{{ detalle.producto.nombre }}</h6>
						</a>
						<p>
							$<span>{{ detalle.monto.toString().substring(0,
								detalle.monto.toString().indexOf('.')) }},<sup>{{
									detalle.monto.toString().substring(detalle.monto.toString().indexOf('.')
									+ 1) }}</sup>
							</span>
						</p>
					</div>
					<div class="col-3">
						<input type="number" class="form-control" id="inputCant"
							placeholder="1" min="1" [(ngModel)]="detalle.cantidad"
							(change)="cambiarCantidad(detalle)">
					</div>
				</div>
				<div class="row m-checkout-product">
					<input type="text" class="form-control" id="observaciones"
						placeholder="Color|Tamaño|Medida|Otras"
						[(ngModel)]="detalle.observacion">
					<button type="submit" class="btn"
						(click)="agregarObservacion(detalle)">
						<span class="lnr lnr-pencil"></span>
					</button>
				</div>
				<div class="g-bar"></div>
			</div>
		</div>

		<div class="row prices">
			<p class="first-p">
				Subtotal: $<span>{{ subTotal.substring(0,
					subTotal.indexOf('.')) }},<sup>{{
						subTotal.substring(subTotal.indexOf('.') + 1) }}</sup>
				</span>
			</p>
		</div>
		<div class="row prices" *ngIf="gastosEntrega != null">
			<p>
				Gastos de entrega: $<span>{{ gastosEntrega.substring(0,
					gastosEntrega.indexOf('.')) }},<sup>{{
						gastosEntrega.substring(gastosEntrega.indexOf('.') + 1) }}</sup>
				</span>
			</p>
		</div>
		<div class="row prices discount">
			<input #inputCodigo type="text" class="form-control" id="descuento"
				placeholder="Ingrese código de descuento">
			<button *ngIf="descuento.id == null" type="submit" class="btn"
				(click)="cargarCodigoDescuento()">
				<span class="lnr lnr-plus-circle"></span>
			</button>
		</div>
		<div class="row prices">
			<p>
				Descuento: $<span>{{ descuentoValor.substring(0,
					descuentoValor.indexOf('.')) }},<sup>{{
						descuentoValor.substring(descuentoValor.indexOf('.') + 1) }}</sup>
				</span>
			</p>
		</div>
		<div class="row prices total">
			<p>
				Total: $<span class="total-span">{{ total.substring(0, total.indexOf('.')) }},<sup>{{
						total.substring(total.indexOf('.') + 1) }}</sup></span>
			</p>
		</div>

		<div class="row plus-p">
			<a href="/search"><span class="lnr lnr-plus-circle"></span>Agregar
				m&aacute;s productos</a>
		</div>

		<div class="row buttons">
			<div class="back-for">
				<button type="button" class="btn back" (click)="irPaso(1)">
					<span class="lnr lnr-chevron-left"></span>Volver
				</button>
				<button type="button" class="btn forward" data-toggle="modal"
					data-target="#modalMPago">
					Finalizar pedido<span class="lnr lnr-chevron-right"></span>
				</button>
			</div>
		</div>
	</div>

</section>

<div class="modal fade modal-mp" id="modalMPago" tabindex="-1"
	role="dialog" aria-hidden="true">
	<div class="modal-dialog" role="document">

		<div class="modal-content" [style.display]="displayModal(null)">
			<div class="modal-header">
				<img src="/assets/images/Logo-ER(2).svg" class="img-fluid"
					alt="papeleria">
				<h5 class="modal-title" id="exampleModalLabel">Finalizar compra</h5>
				<button #cerrarModalFinalizar id="cerrarModalFinalizar"
					(click)="elegirTipoPago(null)" type="button" class="close"
					data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<h5 class="modal-title">Realizar Pago</h5>
				<div class="botones-pago">
					<!-- button class="btn btn-secondary btn-pago"
						(click)="elegirTipoPago('MP')">D&eacute;bito o
						Cr&eacute;dito (Mercado Pago)</button -->
						<button class="btn btn-secondary btn-pago" id="checkout-btn" (click)="handleMercadoPago()">Mercado Pago</button>
						<div id="wallet_container"></div>
				</div>
			</div>
		</div>

		<div class="modal-content" [style.display]="displayModal('EFECTIVO')">
			<div class="modal-header">
				<img src="/assets/images/Logo-ER.png" class="img-fluid"
					alt="papeleria">
				<h5 class="modal-title" id="exampleModalLabel">Pago en efectivo</h5>
				<button type="button" (click)="elegirTipoPago(null)" class="close"
					data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<h5 class="modal-title">Recibir&aacute; un mail para confirmar
					su compra a su cuenta de correo electr&oacute;nico {{
					logueado.email }}.</h5>
				<button class="btn btn-secondary" (click)="confirmarPagoEfectivo()">Confirmar</button>
			</div>
		</div>

		<div class="modal-content" [style.display]="displayModal('MP')">
			<div class="modal-header">
				<img src="/assets/images/Logo-ER(2).svg" class="img-fluid"
					alt="papeleria">
				<h5 class="modal-title" id="exampleModalLabel">Datos de tu
					tarjeta</h5>
				<button type="button" (click)="elegirTipoPago(null)" class="close"
					data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<form id="form-checkout">
					<div id="form-checkout__cardNumber" class="form-control"></div>
					<div id="form-checkout__expirationDate" class="form-control"></div>
					<div id="form-checkout__securityCode" class="form-control"></div>
					<input type="text" id="form-checkout__cardholderName"
						placeholder="Titular de la tarjeta" class="form-control" />
					<select
						id="form-checkout__issuer" name="issuer"
						class="selectvio form-control">
						<option value="" disabled selected>Banco emisor</option>
					</select> <select id="form-checkout__installments" name="installments"
						class="selectvio form-control">
						<option value="" disabled selected>Cuotas</option>
					</select>
					<div>
						<label for="docType">TIPO DE DOCUMENTO</label> <select
							id="docType" name="docType" data-checkout="docType"
							class="selectvio form-control"></select>
					</div>
					<input type="text" id="form-checkout__identificationNumber"
						name="identificationNumber" placeholder="N&uacute;mero de documento"
						class="form-control" /> <input type="email"
						id="form-checkout__email" name="email" placeholder="E-mail"
						class="form-control" />
					<div>
						<input type="hidden" name="transactionAmount"
							id="transactionAmount" value="{{ totalNumber }}"
							class="form-control" />
						<input type="hidden" name="description"
							class="form-control" />
						<input type="hidden"
							name="paymentMethodId" id="paymentMethodId" class="form-control" />
						<div class="total-mp">
							<p>
								TOTAL A PAGAR: <span>${{ total }}</span>
								<button type="submit" class="btn btn-secondary" id="pay">PAGAR</button>
							</p>
						</div>
					</div>
				</form>
			</div>
		</div>
		<div class="modal-content" [style.display]="displayModal('OTROS')">
			<div class="modal-header">
				<img src="/assets/images/Logo-ER.png" class="img-fluid"
					alt="papeleria">
				<button type="button" (click)="elegirTipoPago(null)" class="close"
					data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>

			<div class="modal-body">
				<h5 class="modal-title">Si quer&eacute;s abonar con Mercado
					Pago, contactanos y te enviamos un link de pago.</h5>
			</div>

			<div class="container">
				<div class="row">
					<button class="btn btn-success">
						<!-- Cambiar "btn-secondary" a "btn-success" para el color verde -->
						<a href="https://api.whatsapp.com/send?phone=2616607476"
							style="text-decoration: none; color: white;"> <img
							src="/assets/images/whatsapp-24.png" alt="whatsapp"
							style="vertical-align: middle;"> ¡Contactanos!
						</a>
					</button>
				</div>
			</div>
		</div>
	</div>
</div>

<button #botonAlerta id="botonAlerta" style="display: none;"
	type="button" data-toggle="modal" data-target="#modalAlerta"></button>

<div class="modal fade" id="modalAlerta" tabindex="-1" role="dialog"
	aria-labelledby="modalAlertaLabel" aria-hidden="true">
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header">
				<button #botonCerrarAlerta type="button" class="close"
					data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body text-center">{{ mensaje }}</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-primary" data-dismiss="modal">OK</button>
			</div>
		</div>
	</div>
</div>

<button #botonModalEliminar style="display: none;" type="button"
	data-toggle="modal" data-target="#modalEliminar"></button>

<div class="modal fade modal-mp" id="modalEliminar" tabindex="-1"
	role="dialog" aria-hidden="true">
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header">
				<button #cerrarModalEliminar type="button" class="close"
					data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body text-center">Está a punto de eliminar
				este item de su carrito de compras.</div>
			<div class="modal-body">
				<div class="botones-pago">
					<button class="btn btn-primary btn-pago"
						(click)="cancelarEliminar()">Cancelar</button>
					<button class="btn btn-primary btn-pago"
						(click)="eliminarDetalle()">Confirmar</button>
				</div>
			</div>
		</div>
	</div>
</div>

<app-footer></app-footer>
