<div class="box-wh-m d-block d-md-none">
	<div class="container">
		<div class="row">
			<a href="https://api.whatsapp.com/send?phone=2616607476"><img
				src="/assets/images/whatsapp-24.png" alt="whatsapp"></a>
		</div>
	</div>
</div>
<div class="box-wh d-none d-md-block">
	<div class="container">
		<div class="row">
			<a href="https://api.whatsapp.com/send?phone=2616607476"><img
				src="/assets/images/whatsapp-24.png" alt="whatsapp">¡Contactanos! </a>
		</div>
	</div>
</div>

<footer class="mt-3">
	<div class="container">
		<div class="row">
			<div class="col-12 col-md-4">
				<h6>CONTACTO</h6>
				<a class="d-inline d-md-none"
					href="http://www.facebook.com/papeleriaentrerios" target="_blank"><img
					src="/assets/images/icon-facebook.svg" alt="" class="img-fluid"></a>
				<a class="d-inline d-md-none"
					href="https://instagram.com/papeleria.entrerios" target="_blank"><img
					src="/assets/images/inst-b.png" alt="" class="img-fluid"></a> <a
					class="d-inline d-md-none" href="https://twitter.com/PapEntreRios"
					target="_blank"><img src="/assets/images/icon-twitter.svg"
					alt="" class="img-fluid"></a>
				<div class="colorbar2">
					<span></span>
				</div>
				<ul class="contact">

					<li><a><span class="lnr lnr-phone"></span>0261 441-3550</a></li>
					<li><a
						href="https://api.whatsapp.com/send?phone=2616607476"><img
							src="/assets/images/whatsapp-24.png" class="img-fluid wh-icon"
							alt="whatsapp">0261 660-7476</a></li>
					<li><a href="https://goo.gl/maps/f5qRH9tDYG8vzPbN8"
						target="_blank"><span class="lnr lnr-map-marker"></span>Entre
							Ríos 72, Mendoza</a></li>
					<li><a href="https://goo.gl/maps/Skq77Q3NzjpTBJYk8"
						target="_blank"><span class="lnr lnr-map-marker"></span>San
							Martín 1126, Mendoza</a></li>
					<li class="d-block d-md-none"><a target="_blank" href="/faq"><span
							class="lnr lnr-envelope"></span> Preguntas Frecuentes</a></li>
				</ul>
			</div>

			<div class="col-md-4 d-none d-md-block">
				<h6>ENLACES</h6>
				<div class="colorbar2">
					<span></span>
				</div>
				<ul class="contact">
					<li><a target="_blank" href="#"><span class="lnr lnr-flag"></span>
							Más por mi Escuela</a></li>
					<li><a href="#"><span class="lnr lnr-spell-check"></span>
							Listas Escolares</a></li>
					<li><a target="_blank" href="/faq"><span
							class="lnr lnr-envelope"></span> Preguntas Frecuentes</a></li>
					<li><a href="/support"><span class="lnr lnr-map"></span>info@papeleriaentrerios.com</a>
					</li>
				</ul>
			</div>

			<div class="col-md-4 d-none d-md-block">
				<h6>SEGUINOS</h6>
				<div class="colorbar2">
					<span></span>
				</div>
				<ul class="contact">
					<li><a href="http://www.facebook.com/papeleriaentrerios"
						target="_blank"><img src="/assets/images/icon-facebook.svg"
							alt="" class="img-fluid"> Facebook</a></li>
					<li><a href="https://twitter.com/PapEntreRios" target="_blank"><img
							src="/assets/images/icon-twitter.svg" alt="" class="img-fluid">
							Twitter</a></li>
					<li><a href="https://instagram.com/papeleria.entrerios"
						target="_blank"><img src="/assets/images/inst-b.png" alt=""
							class="img-fluid"> Instagram</a></li>
				</ul>
			</div>
		</div>
	</div>
	<div class="info">
		<div class="container">
			<div class="row">
				<div class="graybar"></div>
				<p>Horarios de atención: Lunes a viernes de 09:00 a 13:30 hs y de
					16:00 a 19:30 hs | Sábados de 09:30 a 13:30 hs</p>
				<br>
				<div class="graybar"></div>
				<p>Los precios expresados incluyen IVA. Las fotografías son a
					modo ilustrativo. Kadima S.A se reserva el derecho de modificar los
					precios promocionados.</p>
				<p>
					© 2025, Kadima S.A., Todos los derechos reservados. - Desarrollado
					por <a target="_blank" href="http://quintoimpacto.net/">QUINTO
						IMPACTO</a>.
				</p>
			</div>
		</div>
	</div>

</footer>
