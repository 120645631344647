/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal-alerta.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./modal-alerta.component";
var styles_ModalAlertaComponent = [i0.styles];
var RenderType_ModalAlertaComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalAlertaComponent, data: {} });
export { RenderType_ModalAlertaComponent as RenderType_ModalAlertaComponent };
export function View_ModalAlertaComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "modal fade show d-block"], ["role", "dialog"], ["style", "background-color: rgba(0,0,0,0.5);"], ["tabindex", "-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "modal-dialog"], ["role", "document"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 9, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cerrarModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "modal-body text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, [" ", " "])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cerrarModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["OK"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.mensaje; _ck(_v, 8, 0, currVal_0); }); }
export function View_ModalAlertaComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal-alerta", [], null, null, null, View_ModalAlertaComponent_0, RenderType_ModalAlertaComponent)), i1.ɵdid(1, 114688, null, 0, i2.ModalAlertaComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalAlertaComponentNgFactory = i1.ɵccf("app-modal-alerta", i2.ModalAlertaComponent, View_ModalAlertaComponent_Host_0, { mensaje: "mensaje" }, { modalClosed: "modalClosed" }, []);
export { ModalAlertaComponentNgFactory as ModalAlertaComponentNgFactory };
