/// <reference types="@types/googlemaps" />
import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { VentaServicio } from 'src/app/servicios/venta.servicio';
import { Cliente } from 'src/app/entidades/cliente';
import { Router } from '@angular/router';
import { DetalleVenta } from 'src/app/entidades/detalle-venta';
import { ProductoServicio } from 'src/app/servicios/producto.servicio';
import { Direccion } from 'src/app/entidades/direccion';
import { ClienteServicio } from 'src/app/servicios/cliente.servicio';
import { Departamento } from 'src/app/entidades/departamento';
import { DepartamentoServicio } from 'src/app/servicios/departamento.service';
import { MapsAPILoader } from '@agm/core';
import { Descuento } from 'src/app/entidades/descuento';
import { DescuentoServicio } from 'src/app/servicios/descuento.service';
import { environment } from 'src/environments/environment';
import { Producto } from 'src/app/entidades/producto';
declare var google: any;
declare var createCheckoutButton: any;

@Component({
	selector: 'app-check-out',
	templateUrl: './check-out.component.html',
	styleUrls: ['./check-out.component.scss']
})

export class CheckOutComponent implements OnInit {
	
	
	marker: google.maps.Marker | null = null; // Define el marcador

	nuevaDireccion: any = null;

	paso = 1;

	logueado: Cliente;

	carrito: DetalleVenta[] = [];

	subTotal: string = null;
	subTotalExcluidos: string = null;
	subTotalResto: string = null;

	gastosEntrega: string = null;

	descuentoValor: string = '0.00';

	total: string = null;

	mensaje: string = null;
	totalCarrito: string = null;

	@ViewChild("botonMP") botonMP: ElementRef;
	@ViewChild("botonEfectivo") botonEfectivo: ElementRef;
	@ViewChild("cerrarModalFinalizar") cerrarModalFinalizar: ElementRef;

	@ViewChild("botonAlerta") botonAlerta: ElementRef;
	@ViewChild("botonCerrarAlerta") botonCerrarAlerta: ElementRef;
	@ViewChild("observaciones") observaciones: ElementRef;
	@ViewChild("horarioEnvio") horarioEnvio: ElementRef;

	@ViewChild("latitudInput") latitudInput: ElementRef;
	@ViewChild("longitudInput") longitudInput: ElementRef;

	@ViewChild("mapAgm") mapAgm: ElementRef;

	@ViewChild("botonModalEliminar") botonModalEliminar: ElementRef;
	@ViewChild("cerrarModalEliminar") cerrarModalEliminar: ElementRef;

	@ViewChild("inputCodigo") inputCodigo: ElementRef;

	detalleEliminar: DetalleVenta;

	iconoTarjeta: string = "/assets/images/favicon-16x16.png";

	totalNumber: number;

	tipoEnvio: string = 'RETIRO';

	direccionSeleccionada: Direccion = null;

	direcciones: String[] = [];

	tipoPago: string = null;

	mostrar: number = 5;
	mostrando: boolean = false;

	departamentos: Departamento[] = [];
	departamentoElegido: Departamento;

	latitude: number = -32.889961293397434;
	longitude: number = -68.84324181655273;
	zoom: number = 15;

	placeId: string = "";

	cantidadOriginal: number | null = null;

	private autocompleteService;

	descuento: Descuento = { id: null, codigo: null, porcentaje: null };

	productoExcluido(nombreProducto: string): boolean {
		const keyword = "resma";
		// Convert both the product name and keyword to lowercase for case-insensitive comparison
		const lowercaseNombreProducto = nombreProducto.toLowerCase();
		const lowercaseKeyword = keyword.toLowerCase();
		// Check if the lowercase product name includes the lowercase keyword
		return lowercaseNombreProducto.includes(lowercaseKeyword);
	}
	
	async handleMercadoPago() {
	
		try {
		let observaciones = localStorage.getItem('observaciones') || '';
		let descuento = localStorage.getItem('descuentoId') || '';
		const nombreElemento = document.querySelector("[data-nombre]");
		const cantidadElemento = document.querySelector("[data-cantidad]");
		const montoElemento = document.querySelector(".total-span");
			
		observaciones = observaciones.replace(/\t+/g, '').trim();
	
		  if (!nombreElemento || !cantidadElemento || !montoElemento) {
			throw new Error("Faltan elementos necesarios en el DOM.");
		  }
	
		  const totalTexto = montoElemento.textContent.replace(/\s+/g, '');
		  const totalNumerico = parseFloat(totalTexto.replace(',', '.'));
	
		  if (isNaN(totalNumerico)) {
			throw new Error("El valor total extraído no es un número válido.");
		  }
	
		  const email = this.logueado.email;
		  const cantidad = parseInt(cantidadElemento.getAttribute("data-cantidad"));
	
		  if (isNaN(cantidad) || cantidad <= 0) {
			throw new Error("Cantidad no válida.");
		  }
	
		  const montoUnitario = totalNumerico / cantidad;
		  const gastosEnvioElemento = localStorage.getItem("montoEnvio");
		  let direccionSeleccionada = '';
		  let localidad = '';
			
			if (this.direccionSeleccionada && this.direccionSeleccionada.domicilio) {
			  direccionSeleccionada = this.direccionSeleccionada.domicilio;
			}
			
			if (this.direccionSeleccionada && this.direccionSeleccionada.localidad) {
			  localidad = this.direccionSeleccionada.localidad;
			}
			
		  const domicilioCompleto = `${direccionSeleccionada} ${localidad}`.trim();
		  const tipoEnvio = localStorage.getItem('tipoEnvio');

		  const latitud = localStorage.getItem('latitud');
		  const longitud = localStorage.getItem('longitud');
		  const horario = localStorage.getItem('horario');
	
		  const userBuyer = {
			emailElemento: email,
			nombreElemento: nombreElemento.getAttribute("data-nombre"),
			cantidadElemento: cantidad,
			montoElemento: montoUnitario,
			gastosEntrega: gastosEnvioElemento,
			direccion: domicilioCompleto,
			tipoEnvio: tipoEnvio,
			observaciones: observaciones,
			latitud: latitud,
			longitud: longitud,
			horario: horario,
			descuento: descuento

		  };
	
		  if (!userBuyer.nombreElemento || isNaN(userBuyer.cantidadElemento) || isNaN(userBuyer.montoElemento)) {
			throw new Error("Datos inválidos en userBuyer.");
		  }
	
		  const response = await fetch(`${environment.baseUrl}/api/mp`, {
			method: 'POST',
			headers: {
			  'Accept': 'Application/json',
			  'Content-Type': 'Application/json',
			},
			body: JSON.stringify(userBuyer)
		  });
	
		  const preference = await response.text();
	
		  this.createCheckoutButton(preference);
	
		} catch (error) {
		  console.error("Error en handleMercadoPago:", error);
		}
	  }
	
	  createCheckoutButton(preference: string) {
		createCheckoutButton(preference);
	  }

	pagarConMercadoPago(nombre: string, cantidad: number, monto: number, email : string): void {
		const userBuyer = {
			email : this.logueado.email.toString,
            title: nombre,
            quanty: cantidad,
            price: monto,
		};
    }

	constructor(private router: Router, private ventaServicio: VentaServicio, private productoServicio: ProductoServicio,
		private clienteServicio: ClienteServicio, private departamentoServicio: DepartamentoServicio, private mapsAPILoader: MapsAPILoader,
		private cd: ChangeDetectorRef, private descuentoServicio: DescuentoServicio) {
		this.mapsAPILoader.load().then(() => {
			this.autocompleteService = new
				google.maps.places.AutocompleteService();
		});
	}

	ngOnInit() {
		const mapContainer = document.getElementById('map');
		if (mapContainer) {
		  this.map = new google.maps.Map(mapContainer, {
			center: { lat: this.latitude, lng: this.longitude },
			zoom: 12,
		  });
	  
		  this.actualizarMapa(this.latitude, this.longitude);
	  
		  this.map.addListener('click', (event: any) => {
			if (event.latLng) {
			  this.handleMapClick(event.latLng.lat(), event.latLng.lng());
			}
		  });
		}
		this.obtenerLogueado();
		this.obtenerCarrito();
		this.obtenerDireccion();
		this.obtenerDepartamentos();
		localStorage.removeItem('direccion');
		localStorage.removeItem('horario');
		localStorage.removeItem('observaciones');
		localStorage.removeItem('latitud');
		localStorage.removeItem('longitud');
		localStorage.removeItem('descuentoId');
		window.myFunction = this.angularFunction.bind(this);
		
	}

	handleMapClick(lat: number, lng: number) {
		this.actualizarMapa(lat, lng);
	  
		const geocoder = new google.maps.Geocoder();
		geocoder.geocode({ location: { lat, lng } }, (results, status) => {
		  if (status === 'OK' && results[0]) {
			const addressComponents = results[0].address_components;
			this.actualizarCamposDireccion(addressComponents);
		  } else {
			console.error('Error al obtener la dirección al hacer clic en el mapa:', status);
		  }
		});
	}

	obtenerDireccionDesdeCoordenadas(lat: number, lng: number) {
		const geocoder = new google.maps.Geocoder();
		geocoder.geocode({ location: { lat, lng } }, (results, status) => {
		  if (status === 'OK' && results[0]) {
			const addressComponents = results[0].address_components;
			this.actualizarCamposDireccion(addressComponents);
		  } else {
			console.error('Error al obtener la dirección:', status);
		  }
		});
	}

	onMapClick(event: any) {
		if (this.tipoEnvio === 'ENVIO') {
		  const lat = event.coords.lat;
		  const lng = event.coords.lng;
	  
		  this.latitude = lat;
		  this.longitude = lng;
	  
		  const geocoder = new google.maps.Geocoder();
		  geocoder.geocode({ location: { lat, lng } }, (results, status) => {
			if (status === 'OK' && results[0]) {
			  const addressComponents = results[0].address_components;
	  
			  this.actualizarCamposDireccion(addressComponents);
			} else {
			  console.error('Error al obtener la dirección:', status);
			}
		  });
		}
		else {
			this.mensaje = 'Seleccione "Envío a domicilio" para marcar una ubicación en el mapa.';
			let alert: HTMLElement = this.botonAlerta.nativeElement;
			alert.click();
		}
	}	

	angularFunction(data: any): void {

    }

	map: any;
	mapReady($event) {
		this.map = $event;
	}

	markerDragEnd(event: any) {
		const lat = event.coords.lat;
		const lng = event.coords.lng;
	  
		const geocoder = new google.maps.Geocoder();
		const latLng = new google.maps.LatLng(lat, lng);
	  
		geocoder.geocode({ location: latLng }, (results, status) => {
		  if (status === 'OK' && results[0]) {
			const addressComponents = results[0].address_components;
			this.actualizarCamposDireccion(addressComponents);
		  } else {
			console.error('Error al obtener la dirección: ', status);
		  }
		});
	  }
	  
	  actualizarCamposDireccion(addressComponents: any[]) {
		const address = {
		  calle: '',
		  numeracion: '',
		  provincia: '',
		  localidad: '',
		  cp: '',
		};
	  
		addressComponents.forEach(component => {
		  const types = component.types;
		  if (types.includes('route')) {
			address.calle = component.long_name;
		  }
		  if (types.includes('street_number')) {
			address.numeracion = component.long_name;
		  }
		  if (types.includes('administrative_area_level_1')) {
			address.provincia = component.long_name;
		  }
		  if (types.includes('locality')) {
			address.localidad = component.long_name;
		  }
		  if (types.includes('postal_code')) {
			address.cp = component.long_name;
		  }
		});
	  
		this.nuevaDireccion = {
			domicilio: `${address.calle} ${address.numeracion}`,
			provincia: address.provincia,
			localidad: address.localidad,
			cp: address.cp,
		  };
	  
	}
	  

	mostrarMas() {
		this.mostrar = this.carrito.length;
		this.mostrando = true;
	}

	elegirTipoPago(tipo) {
		this.tipoPago = tipo;
	}

	confirmarPagoEfectivo() {
		let dire = null;
		if (this.direccionSeleccionada != null) {
			let domicilio = this.direccionSeleccionada.domicilio;
			let localidad = this.direccionSeleccionada.localidad;
			let departamento = this.direccionSeleccionada.departamento;
			let cp = this.direccionSeleccionada.cp;
			dire = domicilio + ', ' + localidad + ', ' + departamento + ' - ' + cp;
		}
		this.ventaServicio.pagoEnEfectivo(this.logueado.id, localStorage.getItem('observaciones'), this.gastosEntrega, dire, localStorage.getItem('horario'), this.latitude, this.longitude, this.descuento.id).subscribe(res => {
			this.mensaje = "Su solicitud está siendo procesada. Revise su casilla de correo para finalizar el proceso de compra.";
			let alert: HTMLElement = this.botonAlerta.nativeElement;
			alert.click();
			setTimeout(() => {
				this.cerrarModalFinalizar.nativeElement.click();
				this.botonCerrarAlerta.nativeElement.click();
				this.router.navigate(['/']);
			}, 3000)
		}, error => {
			this.mensaje = "Hubo un error al enviar el mail de confirmación. Intente nuevamente más tarde.";
			let alert: HTMLElement = this.botonAlerta.nativeElement;
			alert.click();
		})
	}

	marcarUbicacion() {
		const direccion = `${this.nuevaDireccion.domicilio}, ${this.nuevaDireccion.localidad}, ${this.nuevaDireccion.provincia}`;
		const geocoder = new google.maps.Geocoder();
	  
		geocoder.geocode({ address: direccion }, (results, status) => {
		  if (status === 'OK' && results[0]) {
			const location = results[0].geometry.location;
			this.latitude = location.lat();
			this.longitude = location.lng();
			this.actualizarMapa(location.lat(), location.lng());
		  } else {
			console.error('Error al geocodificar la nueva dirección: ', status);
		  }
		});
	  }
	  

	elegirDireccion(event) {
		let direccion = event.srcElement.value;
	  
		if (direccion != 'agregar') {
		  this.nuevaDireccion = null;
		  this.direccionSeleccionada = new Direccion();
	  
		  this.direccionSeleccionada.domicilio = direccion.substring(0, direccion.indexOf(','));
		  this.direccionSeleccionada.localidad = direccion.substring(direccion.indexOf(',') + 2, direccion.lastIndexOf(','));
		  this.direccionSeleccionada.departamento = direccion.substring(direccion.lastIndexOf(',') + 2, direccion.indexOf(" -"));
		  this.direccionSeleccionada.cp = direccion.substring(direccion.indexOf(" -") + 3);
	  
		  let dire = this.direccionSeleccionada.domicilio + ', ' + this.direccionSeleccionada.departamento + ', Mendoza';
		  this.getPlacePredictions(dire);
	  
		  this.departamentos.forEach(d => {
			if (d.nombre == this.direccionSeleccionada.departamento) {
			  this.departamentoElegido = d;
			}
		  });
	  
		  let montoMinimo = this.departamentoElegido.montoMinimo;
		  if (montoMinimo < Number(this.subTotal)) {
			this.gastosEntrega = '0.00';
		  } else {
			this.gastosEntrega = this.ordenarDecimales('' + this.departamentoElegido.costoEnvio);
		  }
	  
		  const geocoder = new google.maps.Geocoder();
		  geocoder.geocode({ address: dire }, (results, status) => {
			if (status === 'OK' && results[0]) {
			  const location = results[0].geometry.location;
	  
			  this.latitude = location.lat();
			  this.longitude = location.lng();
	  
			  this.actualizarMapa(location.lat(), location.lng());
			} else {
			  console.error('No se pudo geocodificar la dirección seleccionada: ', status);
			}
		  });
	  
		} else {
		  this.gastosEntrega = null;
		  this.direccionSeleccionada = null;
		  this.nuevaDireccion = new Direccion();
		}
	  }
	  
	  actualizarMapa(lat: number, lng: number) {
		if (this.map) {
		  this.map.setCenter({ lat, lng });
		  this.marker.setPosition({ lat, lng });
		}
	  }
	  

	getPlacePredictions(term: string) {
		this.autocompleteService.getPlacePredictions({ input: term }, data => {
			this.placeId = data[0].place_id;
			this.getLatLong();
		});
	}

	getLatLong() {
		let placeService = new google.maps.places.PlacesService(this.map);
		placeService.getDetails({ placeId: this.placeId }, (result, status) => {
			this.latitude = result.geometry.location.lat();
			this.longitude = result.geometry.location.lng();
			let alert: HTMLElement = this.mapAgm.nativeElement;
			alert.click();
		});
	}

	checkedTipo(tipo) {
		if (this.tipoEnvio == tipo) {
			return true;
		} else {
			return false;
		}
	}

	setTipoEnvio(tipo) {
		this.tipoEnvio = tipo;
		this.nuevaDireccion = null;
		this.direccionSeleccionada = null;
		if (this.tipoEnvio != 'ENVIO') {
			this.gastosEntrega = null;
		}
		this.obtenerTotal();
	}

	disabledTipo() {
		if (this.tipoEnvio == 'RETIRO') {
			return true;
		}
	}

	irPaso(paso) {
		this.paso = paso;
	}

	obtenerDireccion() {
		this.clienteServicio.buscarDireccion(this.logueado.id).subscribe(res => {
			this.direcciones = res;
		}, error => {
			this.mensaje = "Hubo un error al cargar las direcciones cargadas. Intente nuevamente más tarde.";
			let alert: HTMLElement = this.botonAlerta.nativeElement;
			alert.click();
		})
	}

	validarEnvio(): boolean {
		if (!this.tipoEnvio) {
			return false; 
		}
		if (this.tipoEnvio === 'RETIRO') {
			return true; 
		}
		if (this.tipoEnvio === 'ENVIO') {
			const horarioValido = this.horarioEnvio &&
				this.horarioEnvio.nativeElement &&
				typeof this.horarioEnvio.nativeElement.value === 'string' &&
				this.horarioEnvio.nativeElement.value.trim().length > 0;
			const direccionValida = this.direccionSeleccionada != null || (
				this.nuevaDireccion &&
				typeof this.nuevaDireccion.domicilio === 'string' && this.nuevaDireccion.domicilio.trim().length > 0 &&
				typeof this.nuevaDireccion.cp === 'string' && this.nuevaDireccion.cp.trim().length > 0 &&
				typeof this.nuevaDireccion.departamento === 'string' && this.nuevaDireccion.departamento.trim().length > 0 &&
				typeof this.nuevaDireccion.localidad === 'string' && this.nuevaDireccion.localidad.trim().length > 0
			);
	
			return horarioValido && direccionValida;
		}
	
		return false;
	}
		
	pasoSiguiente() {
		if (this.tipoEnvio == 'ENVIO') {
			localStorage.setItem('tipoEnvio', this.tipoEnvio);
			
			let domicilio = this.direccionSeleccionada 
							? this.direccionSeleccionada.domicilio 
							: this.nuevaDireccion 
							? this.nuevaDireccion.domicilio 
							: '';
	
			if (domicilio) {
				localStorage.setItem('ubicacion', domicilio);
			} else {
				console.error("Error: No se ha ingresado un domicilio válido.");
			}
		}
	
		if (this.tipoEnvio == 'RETIRO') {
			if (this.observaciones.nativeElement.value != '') {
				localStorage.setItem('observaciones', this.observaciones.nativeElement.value);
			}
			this.irPaso(2);
		} else {
			if (this.nuevaDireccion == null) {
				this.irPaso(2);
				let dire = this.direccionSeleccionada.domicilio + ', ' + this.direccionSeleccionada.localidad + ', ' + this.direccionSeleccionada.departamento + ' - ' + this.direccionSeleccionada.cp;
				localStorage.setItem('direccion', dire);
				localStorage.setItem('horario', this.horarioEnvio.nativeElement.value);
				localStorage.setItem('montoEnvio', this.gastosEntrega);
				localStorage.setItem('latitud', '' + this.latitude);
				localStorage.setItem('longitud', '' + this.longitude);
				if (this.observaciones.nativeElement.value != '') {
					localStorage.setItem('observaciones', this.observaciones.nativeElement.value);
				}
				this.obtenerTotal();
			} else {
				if (this.nuevaDireccion.cp && this.nuevaDireccion.departamento && this.nuevaDireccion.domicilio &&
					this.nuevaDireccion.localidad) {
					let dire = this.nuevaDireccion.domicilio + ', ' + this.nuevaDireccion.localidad + ', ' + this.departamentoElegido.nombre + ' - ' + this.nuevaDireccion.cp;
					this.clienteServicio.agregarDireccion(dire, this.logueado.id).subscribe(res => {
						this.mensaje = "Dirección agregada al cliente.";
						let alert: HTMLElement = this.botonAlerta.nativeElement;
						alert.click();
						this.direccionSeleccionada = { ...this.nuevaDireccion };
						localStorage.setItem('direccion', dire);
						localStorage.setItem('horario', this.horarioEnvio.nativeElement.value);
						localStorage.setItem('montoEnvio', this.gastosEntrega);
						localStorage.setItem('latitud', '' + this.latitude);
						localStorage.setItem('longitud', '' + this.longitude);
						if (this.observaciones.nativeElement.value != '') {
							localStorage.setItem('observaciones', this.observaciones.nativeElement.value);
						}
						setTimeout(() => {
							this.irPaso(2);
							this.obtenerDireccion();
							this.obtenerTotal();
						}, 3000);
						localStorage.removeItem('direccion');
					}, error => {
						this.mensaje = "Hubo un error al cargar la nueva dirección. Intente nuevamente más tarde.";
						let alert: HTMLElement = this.botonAlerta.nativeElement;
						alert.click();
					});
				} else {
					this.mensaje = "Complete todos los campos de su nueva dirección.";
					let alert: HTMLElement = this.botonAlerta.nativeElement;
					alert.click();
				}
			}
		}
	}

	obtenerLogueado() {
		this.logueado = JSON.parse(localStorage.getItem('logueado'));
		if (this.logueado == null) {
			this.router.navigate(['/']);
		}
	}

	pathImagen(path: string) {
		return this.productoServicio.getImagen(path);
	}

	obtenerCarrito() {
		this.ventaServicio.getCarrito(this.logueado.id).subscribe(res => {
			if (res != null) {
				this.carrito = res['detalleVenta'];
				this.carrito.forEach(i => {
					i.producto.precio1 = this.ordenarDecimales(i.producto.precio1);
					i.producto.precio2 = this.ordenarDecimales(i.producto.precio2);
					i.producto.precio3 = this.ordenarDecimales(i.producto.precio3);
					i.producto.precio4 = this.ordenarDecimales(i.producto.precio4);
					i.producto.precioOferta = this.ordenarDecimales(i.producto.precioOferta);
					i.monto = this.ordenarDecimales(i.monto);
				});

				const productosExcluidos = this.carrito.filter(detalle => this.productoExcluido(detalle.producto.nombre));
				const productosResto = this.carrito.filter(detalle => !this.productoExcluido(detalle.producto.nombre));

				const subTotalExcluidos = productosExcluidos.reduce((total, detalle) => total + parseFloat(detalle.monto), 0);
				const subTotalResto = productosResto.reduce((total, detalle) => total + parseFloat(detalle.monto), 0);

				this.subTotal = this.ordenarDecimalesSubtotal(subTotalExcluidos + subTotalResto);
				this.subTotalExcluidos = this.ordenarDecimalesSubtotal(subTotalExcluidos);
				this.subTotalResto = this.ordenarDecimalesSubtotal(subTotalResto);

				this.obtenerTotal();
			} else {
				this.router.navigate(['/']);
			}
		}, error => {
			this.mensaje = "Hubo un error al obtener los ítems del carrito. Intente nuevamente más tarde.";
			let alert: HTMLElement = this.botonAlerta.nativeElement;
			alert.click();
		})
	}

	ordenarDecimalesSubtotal(precio) {
		if (Number(precio) % 1 != 0) {
			let decimales: string = precio.toString().substring(precio.toString().indexOf('.') + 1);
			if (decimales.length == 1) {
				decimales = decimales + '0';
			} else if (decimales.length >= 2) {
				decimales = decimales.substring(0, 2);
			}
			precio = precio.toString().substring(0, precio.toString().indexOf('.')) + '.' + decimales;
		} else {
			precio = precio + '.00';
		}
		return precio;
	}

	ordenarDecimales(valor) {
		let numero;
		if (Number(valor) % 1 != 0) {
			let decimales: string = valor.toString().substring(valor.toString().indexOf('.') + 1);
			if (decimales.length == 1) {
				decimales = decimales + '0';
			} else if (decimales.length >= 2) {
				decimales = decimales.substring(0, 2);
			}
			numero = valor.toString().substring(0, valor.toString().indexOf('.')) + '.' + decimales;
		} else {
			numero = valor + '.00';
		}
		return numero;
	}

	obtenerTotal() {
		let sub = Number(this.subTotalResto);
		let sub2 = Number(this.subTotalExcluidos);
		let gastos = Number(this.gastosEntrega);
		let desc = Number(this.descuentoValor);

		let t;
		if (this.tipoEnvio == 'RETIRO') {
			t = sub - desc + sub2;
		} else {
			t = sub - desc + sub2 + gastos;
		}
		this.total = this.ordenarDecimalesSubtotal(t);
		this.totalNumber = +this.total;
	}

	agregarObservacion(detalle) {
		this.ventaServicio.agregarObservacion(detalle.id, detalle.observacion).subscribe(res => {
			this.mensaje = "Observación agregada al detalle de venta.";
			let alert: HTMLElement = this.botonAlerta.nativeElement;
			alert.click();
		}, error => {
			this.mensaje = "Hubo un error al agregar su observación al detalle de venta.";
			let alert: HTMLElement = this.botonAlerta.nativeElement;
			alert.click();
		})
	}

	elegirTarjeta(event) {
		if (event.target.value.charAt(0) == '4') {
			this.iconoTarjeta = "/assets/images/visa.jpg";
		} else if (event.target.value.charAt(0) == '3') {
			this.iconoTarjeta = "/assets/images/american.jpg";
		} else if (event.target.value.charAt(0) == '5') {
			this.iconoTarjeta = "/assets/images/master.jpg";
		}
	}

	tarjetaIcono() {
		return this.iconoTarjeta;
	}

	botonPagarMP() {
		this.cerrarModalFinalizar.nativeElement.click();
		this.mensaje = "Se está procesando su pago...";
		if (this.logueado != null) {
			this.ventaServicio.vaciarCarrito(this.logueado.id).subscribe(res => {
				this.obtenerCarrito();
				let alert: HTMLElement = this.botonAlerta.nativeElement;
				alert.click()
				window.location.href = "/";
			}, error => {
				this.mensaje = "Hubo un error al vaciar el carrito. Intente nuevamente más tarde";
				let alert: HTMLElement = this.botonAlerta.nativeElement;
				alert.click();
			})
		}
	}

	displayModal(tipo) {
		if (this.tipoPago == tipo) {
			return 'block';
		} else {
			return 'none';
		}
	}

	guardarCantidadOriginal(detalle) {
		var inputCant = document.getElementById('inputCant2' + detalle.id) as HTMLInputElement;
		this.cantidadOriginal = parseFloat(inputCant.value);
	}

	cambiarCantidad(detalle) {
		if (detalle.cantidad == 0) {
			this.obtenerCarrito();
		} else {
			this.ventaServicio.cambiarCantidad(detalle.cantidad, detalle.id, this.logueado.id).subscribe(res => {
				this.mensaje = "Cantidad modificada.";
				let alert: HTMLElement = this.botonAlerta.nativeElement;
				alert.click();
				this.obtenerCarrito();
			}, error => {
				this.mensaje = "No hay stock suficiente de este producto.";
				var inputCant = document.getElementById('inputCant2' + detalle.id) as HTMLInputElement;
				if (inputCant && this.cantidadOriginal !== null) {
					inputCant.value = this.cantidadOriginal.toString();
				}
				let alert: HTMLElement = this.botonAlerta.nativeElement;
				alert.click();
			})
		}
	}

	obtenerDepartamentos() {
		this.departamentoServicio.listarDepartamentos().subscribe(res => {
			this.departamentos = res;
		}, error => {
			this.mensaje = "Error al listar departamentos.";
			let alert: HTMLElement = this.botonAlerta.nativeElement;
			alert.click();
		})
	}

	elegirDepartamento(event) {
		let i = 0;
		this.departamentos.forEach(d => {
			if (i == event.srcElement.value) {
				this.departamentoElegido = this.departamentos[i];
			}
			i++;
		});
		let montoMinimo = this.departamentoElegido.montoMinimo;
		if (montoMinimo < Number(this.subTotal)) {
			this.gastosEntrega = '0.00';
		} else {
			this.gastosEntrega = this.ordenarDecimales('' + this.departamentoElegido.costoEnvio);
		}
		this.marcarUbicacion();
	}

	eliminarDetalle() {
		this.ventaServicio.eliminarDetalle(this.detalleEliminar.id, this.logueado.id).subscribe(res => {
			this.mensaje = "Item eliminado del carrito.";
			let alert: HTMLElement = this.botonAlerta.nativeElement;
			alert.click();
			this.obtenerCarrito();
			let modal: HTMLElement = this.cerrarModalEliminar.nativeElement;
			modal.click();
		}, error => {
			let modal: HTMLElement = this.cerrarModalEliminar.nativeElement;
			modal.click();
			this.mensaje = "Hubo un error al eliminar item del carrito. Intente nuevamente más tarde.";
			let alert: HTMLElement = this.botonAlerta.nativeElement;
			alert.click();
		})
	}

	cancelarEliminar() {
		this.detalleEliminar = null;
		let alert: HTMLElement = this.cerrarModalEliminar.nativeElement;
		alert.click();
	}

	abrirModalEliminar(detalle) {
		this.detalleEliminar = detalle;
		let alert: HTMLElement = this.botonModalEliminar.nativeElement;
		alert.click();
	}

	cargarCodigoDescuento() {
		if (this.inputCodigo.nativeElement.value != '') {
			this.descuentoServicio.getDescuento(this.inputCodigo.nativeElement.value, this.logueado.id).subscribe(res => {
				this.descuento = res;
				this.descuentoValor = this.ordenarDecimales(this.descuento.porcentaje * Number(this.subTotalResto) / 100);
				localStorage.setItem('descuentoId', this.descuento.id)
				this.obtenerTotal();
				this.mensaje = "Usted ha cargado un código de " + this.descuento.porcentaje + "% de descuento.";
				let alert: HTMLElement = this.botonAlerta.nativeElement;
				alert.click();
			}, error => {
				if (error['error'].message != null) {
					this.mensaje = error['error'].message;
					let alert: HTMLElement = this.botonAlerta.nativeElement;
					alert.click();
				} else {
					this.mensaje = "Hubo un error al validar código de descuento. Intente nuevamente más tarde.";
					let alert: HTMLElement = this.botonAlerta.nativeElement;
					alert.click();
				}
			})
		} else {
			this.mensaje = "Complete el campo de código de descuento";
			let alert: HTMLElement = this.botonAlerta.nativeElement;
			alert.click();
		}
	}

}
